import React, { useState } from "react";
import { useSelector } from "react-redux";

import Telegram from "../assets/images/icons/social/telegram-icon.webp";
import Instagram from "../assets/images/icons/social/instagram-icon.webp";
import Facebook from "../assets/images/icons/social/facebook-icon.webp";
import Whatsapp from "../assets/images/icons/social/whatsapp-icon.webp";
import Phone from "../assets/images/icons/phone.webp";
import chatbox from "../assets/images/icons/chat.webp";
import AppleIcon from "../assets/images/icons/apple-apk.webp";
import AndroidIcon from "../assets/images/icons/android-icon.webp";
import TopIcon from "../assets/images/icons/top-icon.webp";
import ChevronDown from "../assets/images/icons/chevron-down.webp";
import WindowIcon from "../assets/images/icons/window-icon.webp";
import ChevronRight from "../assets/images/icons/chevron-right.webp";

import Lazy1 from "../assets/images/icons/fiba.webp";
import Lazy2 from "../assets/images/icons/fifa.webp";
import Lazy3 from "../assets/images/icons/nhl.webp";
import Lazy4 from "../assets/images/icons/wta.webp";
import Lazy5 from "../assets/images/icons/ufc.webp";
import Lazy6 from "../assets/images/icons/uefa.webp";
import Lazy7 from "../assets/images/icons/itf.webp";
import Lazy8 from "../assets/images/icons/atp.webp";

import PaymentPartner1 from "../assets/images/icons/pay-partners/visa.webp";
import PaymentPartner2 from "../assets/images/icons/pay-partners/mastercard.webp";
import PaymentPartner3 from "../assets/images/icons/pay-partners/google-pay.webp";
import PaymentPartner4 from "../assets/images/icons/pay-partners/apple-pay.webp";
import PaymentPartner5 from "../assets/images/icons/pay-partners/bitcoin.webp";
import PaymentPartner6 from "../assets/images/icons/pay-partners/qiwi-new.webp";
import PaymentPartner7 from "../assets/images/icons/pay-partners/ethereum.webp";
import PaymentPartner8 from "../assets/images/icons/pay-partners/tether.webp";
import PaymentPartner9 from "../assets/images/icons/pay-partners/skrill.webp";
import PaymentPartner10 from "../assets/images/icons/pay-partners/payeer.webp";
import PaymentPartner11 from "../assets/images/icons/pay-partners/piastrix.webp";
import PaymentPartner12 from "../assets/images/icons/pay-partners/webmoney.webp";
import PaymentPartner13 from "../assets/images/icons/pay-partners/muchbetter.webp";
import PaymentPartner14 from "../assets/images/icons/pay-partners/astropay.webp";

import BookmakerRatings from "../assets/images/icons/license/bookmaker-ratings.webp";
import Betraja from "../assets/images/icons/license/betraja.webp";
import Casinomentor from "../assets/images/icons/license/casinomentor.webp";
import Bestbitcoincasino from "../assets/images/icons/license/bestbitcoincasino.webp";
import Cricketbettingguru from "../assets/images/icons/license/cricketbettingguru.webp";

import BottomNav from "./BottomNav";

const Footer = () => {
  const { appDetails } = useSelector((state) => state.app);

  const [isActive, setIsActive] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <footer className="footer">
        <div className="foot-top">
          <div className="foot-logo">
            <a href="/">
              <img src={appDetails?.LOGO_URL} alt="Logo" />
            </a>
          </div>

          <div className="top-inner-box">
            <div className={`support ${isActive ? "full-h" : ""}`}>
              <div className="text-call">
                <div>
                  <h6>Support</h6>
                  <p>Write to us if You still have any questions!</p>
                </div>
                {/* <div className="chat_button">Chat</div> */}
                <div className="call">
                  <a href={`tel://${appDetails?.PHONE}`}>
                    <div className="img-box">
                      <img src={Phone} alt="phone" />
                    </div>
                    <span>{appDetails?.PHONE}</span>
                  </a>
                  <a
                    className="toggle-btn d-lg-none"
                    onClick={() => setIsActive(!isActive)}
                  >
                    <img src={ChevronDown} alt="chevron down" />
                  </a>
                </div>
              </div>
              <div className="contacts">
                <p className="heading d-none d-lg-block">Support contacts</p>
                <ul>
                  <li>Technical support</li>
                  <li>
                    <a href="mailto:support@goldengames24x7.social">
                      support@goldengames24x7.social
                    </a>
                  </li>
                </ul>
                <ul>
                  <li>Security service</li>
                  <li>
                    <a href="mailto:security@goldengames24x7.social">
                      security@goldengames24x7.social
                    </a>
                  </li>
                </ul>
                {/* <ul>
                  <li>Contact Us</li>
                  <li>
                    <a href="/contactuss">Contact Us</a>
                  </li>
                </ul> */}
              </div>
            </div>

            <div className="foot-links">
              <div className="information">
                <p className="heading">Information</p>
                <ul>
                  <li>
                    <a href="/privacypolicy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/responsiblegame">Responsible Gaming</a>
                  </li>
                  <li>
                    <a href="/fairplay">Fair Play</a>
                  </li>
                  <li>
                    <a href="/gamesrule">Games Rules</a>
                  </li>
                  <li>
                    <a href="/terms">Terms and Conditions</a>
                  </li>
                </ul>
              </div>
              <div className="categories">
                <p className="heading">Categories</p>
                <ul>
                  <li>
                    <a href="/betby">Sports</a>
                  </li>
                  <li>
                    <a href="#">Promotions</a>
                  </li>
                  <li>
                    <a href="/livecasino">Live-games</a>
                  </li>
                  <li>
                    <a href="/casino/ezgevo-three-card-poker">Poker</a>
                  </li>
                  <li>
                    <a href="/casinogames">Casino</a>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className="download-apk">
              <div className="apps">
                <a href="/ios-instruction" className="app ios d-lg-none">
                  <img src={AppleIcon} alt="android apk" />
                  <div className="text">
                    <span>Application</span>
                    <strong>for iOS</strong>
                  </div>
                  <img
                    src={ChevronRight}
                    alt="chevron right"
                    className="arrow ms-auto d-lg-none"
                  />
                </a>
                <a href="#" className="app ios d-none d-lg-flex">
                  <img src={AppleIcon} alt="android apk" />
                  <div className="text">
                    <span>Application</span>
                    <strong>for iOS</strong>
                  </div>
                  <img
                    src={ChevronRight}
                    alt="chevron right"
                    className="arrow ms-auto d-lg-none"
                  />
                </a>
                <a href="#" className="app android">
                  <img src={AndroidIcon} alt="android apk" />
                  <div className="text">
                    <span>Application</span>
                    <strong>for Android</strong>
                  </div>
                  <img
                    src={ChevronRight}
                    alt="chevron right"
                    className="arrow ms-auto d-lg-none"
                  />
                </a>
              </div>
              <div className="windows d-none d-lg-flex">
                <a href="#" className="windows d-none d-lg-flex">
                  <div className="imgs">
                    <img src={WindowIcon} alt="windows" className="window" />
                    <img
                      src={ChevronRight}
                      alt="chevron right"
                      className="arrow"
                    />
                  </div>
                  <div className="text">
                    <span>Application</span>
                    <strong>for Windows</strong>
                  </div>
                </a>
              </div>
            </div> */}
          </div>
        </div>

        <div className="disclaimer-text mt-5">
          <p>{appDetails?.FOOTER_TEXT || ""}</p>
        </div>

        <div className="foot-middle-separator d-none d-lg-block"></div>

        <div className="foot-bottom">
          <div className="bottom-inner-box">
            {/* <div className="social-links">
              <ul>
                <li>
                  <a
                    className="tg"
                    href={
                      appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"
                    }
                    target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Telegram} alt="telegram" />
                    <span>Telegram</span>
                  </a>
                </li>
                <li>
                  <a
                    className="insta"
                    href={
                      appDetails?.INSTATGRAM != ""
                        ? appDetails?.INSTATGRAM
                        : "#"
                    }
                    target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Instagram} alt="instagram" />
                    <span>Instagram</span>
                  </a>
                </li>
                <li>
                  <a
                    className="fb"
                    href={
                      appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <img src={Facebook} alt="facebook" />
                    <span>Facebook</span>
                  </a>
                </li>
                <li>
                  <a
                    className="wtsp"
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                        : "#"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    <img src={Whatsapp} alt="whatsapp" />
                    <span>Whatsapp</span>
                  </a>
                </li>
              </ul>
            </div> */}
            {/* <div className="sports-promotion">
              <ul>
                <li>
                  <img src={Lazy1} alt="" />
                </li>
                <li>
                  <img src={Lazy2} alt="" />
                </li>
                <li>
                  <img src={Lazy3} alt="" />
                </li>
                <li>
                  <img src={Lazy4} alt="" />
                </li>
                <li>
                  <img src={Lazy5} alt="" />
                </li>
                <li>
                  <img src={Lazy6} alt="" />
                </li>
                <li>
                  <img src={Lazy7} alt="" />
                </li>
                <li>
                  <img src={Lazy8} alt="" />
                </li>
              </ul>
            </div> */}
            <div className="language-scrollup">
              <div className="scrollup">
                <button onClick={scrollToTop}>
                  <img src={TopIcon} alt="scroll to top" />
                </button>
              </div>
            </div>
            {/* <div className="payment-partners">
              <ul>
                <li>
                  <img src={PaymentPartner1} alt="payment partner" />
                </li>
                <li>
                  <img src={PaymentPartner2} alt="payment partner" />
                </li>
                <li>
                  <img src={PaymentPartner3} alt="payment partner" />
                </li>
                <li>
                  <img src={PaymentPartner4} alt="payment partner" />
                </li>
                <li>
                  <img src={PaymentPartner5} alt="payment partner" />
                </li>
                <li>
                  <img src={PaymentPartner6} alt="payment partner" />
                </li>
                <li>
                  <img src={PaymentPartner7} alt="payment partner" />
                </li>
                <li>
                  <img src={PaymentPartner8} alt="payment partner" />
                </li>
                <li>
                  <img src={PaymentPartner9} alt="payment partner" />
                </li>
                <li>
                  <img src={PaymentPartner10} alt="payment partner" />
                </li>
                <li>
                  <img src={PaymentPartner11} alt="payment partner" />
                </li>
                <li>
                  <img src={PaymentPartner12} alt="payment partner" />
                </li>
                <li>
                  <img src={PaymentPartner13} alt="payment partner" />
                </li>
                <li>
                  <img src={PaymentPartner14} alt="payment partner" />
                </li>
              </ul>
            </div> */}

            <div className="copyright">
              <p className="copyRight">© 2024 Goldengames24x7.</p>
            </div>
            {/* <div className="license-section">
              <ul>
                <li>
                  <a href="#">
                    <img
                      src={BookmakerRatings}
                      alt="bookmaker ratings"
                      className="bookmaker"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={Betraja} alt="betraja" className="betraja" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src={Casinomentor}
                      alt="casinomentor"
                      className="casinomentor"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src={Bestbitcoincasino}
                      alt="bestbitcoincasino"
                      className="bitcoincasino"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src={Cricketbettingguru}
                      alt="cricketbettingguru"
                      className="cricket"
                    />
                  </a>
                </li>
                <li>
                  <span>18+</span>
                </li>
              </ul>
            </div> */}
          </div>
        </div>

        {/* <div className="webMobSection d-md-block d-none">
          <ul>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <img src={Phone} alt="whatsapp" />
              </a>
            </li>
            <li>
              <a className="panel" href={`tel://${appDetails?.PHONE}`}>
                <span>24/7</span>
              </a>
            </li>

            <li>
              <a href="#">
                <img src={chatbox} alt="whatsapp" />
              </a>
            </li>
          </ul>
        </div> */}
      </footer>

      <BottomNav />
    </>
  );
};

export default Footer;

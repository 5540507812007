export const APP_CONST = Object.freeze({
  AUTH_ACTIONS: {
    LOGIN: "login",
    REGISTER: "register",
  },
  TOAST_TYPE: {
    ERROR: "error",
    SUCCESS: "success",
  },
  SPORTS_ID: {
    CIRCKET: "4",
    SOCCER: "1",
    TENNIS: "2",
  },
  ALL_MARKET_IDS: {
    CRICKET: "CRICKET_IDS",
    SOCCER: "SOCCER_IDS",
    TENNIS: "TENNIS_IDS",
  },
  ODDS_TYPE: {
    BACK: "back",
    LAY: "lay",
  },
  PLAYER_BANK_DETAILS_TYPE: {
    Bank: "bank",
    Card: "card",
  },
  TEST: {},
  AUTH_MODAL: {
    LOGIN: "login",
    REGISTER: "register",
    MOBILE_NUMBER: "mobileNumber",
    VERIFY_NUMBER: "verifyNumber",
    FORGOTO_PASSWORD: "forgotpassword",
    RESET_PASSWORD: "resetpassword",
  },
  AUTH_MODAL_ACTION_TYPE: {
    MANUAL: "manual",
    AUTO: "auto",
  },
  ORGANISATION_STATUS: {
    Maintenance: "maintenance",
    Live: "live",
    Suspended: "suspended",
  },
  ENVIRONMENTS: {
    PRODUCTION: "production",
    DEVELOPMENT: "development",
  },
});

export const APP_HOST = "localhost:3000";

// export const SIKKIM_COORDINATES = [
//   [88.23083076877835, 27.85191714035538],
//   [88.07833211240867, 27.508260520274074],
//   [88.07977195066582, 27.421074192551277],
//   [88.05058469367633, 27.389378530103862],
//   [88.07097423181042, 27.34069130723402],
//   [88.04976613516331, 27.288556087769354],
//   [88.0270063540189, 27.228729172815246],
//   [88.06858792502183, 27.2186552532429],
//   [88.09737602666763, 27.18239541521008],
//   [88.09943079831237, 27.137093873991255],
//   [88.14425854846446, 27.12000791847437],
//   [88.18513987778945, 27.136549940772813],
//   [88.2187650020727, 27.135787475858827],
//   [88.23582564900263, 27.12118059163756],
//   [88.25372059847251, 27.130707295485784],
//   [88.27217032206774, 27.134098299051477],
//   [88.31014502426609, 27.134402197214726],
//   [88.36156407702896, 27.103964077317947],
//   [88.43732220094562, 27.08863748664872],
//   [88.4905851033322, 27.138502322880385],
//   [88.54765426713627, 27.19065544656631],
//   [88.60244565957338, 27.196091817281626],
//   [88.6494704725418, 27.167638411766305],
//   [88.68140734374745, 27.183036670867057],
//   [88.74108241530854, 27.165585287018267],
//   [88.79079564387342, 27.26300694768027],
//   [88.89736370450782, 27.295724594222904],
//   [88.78219491563115, 27.41730289508183],
//   [88.76090975551, 27.567718263928022],
//   [88.78475736530964, 27.60060285428538],
//   [88.82791737971542, 27.66769241563172],
//   [88.84884368752735, 27.77994955112893],
//   [88.874910808602, 27.884298822572802],
//   [88.83365629826139, 27.92620414002513],
//   [88.83686485853269, 27.971935262276688],
//   [88.81637825209692, 28.01443325634768],
//   [88.73295281612371, 28.061647939009614],
//   [88.61087083219785, 28.079088861406447],
//   [88.56177118056164, 28.02375884214543],
//   [88.16369600776561, 27.916803952265745],
//   [88.23083076877835, 27.85191714035538],
// ];

// export const SIKKIM_COORDINATES = [
//   [73.8, 29.5], // Bottom-left corner
//   [76.0, 29.5], // Bottom-right corner
//   [76.0, 32.5], // Top-right corner
//   [73.8, 32.5], // Top-left corner
//   [73.8, 29.5], // Close the polygon
// ];

export const SIKKIM_COORDINATES = [
  [88.0120333, 27.2162057],
  [88.0120438, 27.2159534],
  [88.0124025, 27.2154828],
  [88.0126421, 27.2152046],
  [88.0128799, 27.2148256],
  [88.0133959, 27.213863],
  [88.0135268, 27.213391],
  [88.0135479, 27.2129746],
  [88.0328652, 27.2212039],
  [88.0618559, 27.2113917],
  [88.0747902, 27.2006874],
  [88.0676541, 27.1904291],
  [88.0832645, 27.1650065],
  [88.0859405, 27.1413679],
  [88.1214018, 27.1256612],
  [88.1279989, 27.1227389],
  [88.1523962, 27.1119311],
  [88.1542971, 27.1129502],
  [88.1633553, 27.1178063],
  [88.169088, 27.1208795],
  [88.1720927, 27.1252125],
  [88.1722448, 27.1271081],
  [88.1733098, 27.1275143],
  [88.1746029, 27.1273112],
  [88.1755157, 27.1295453],
  [88.1794712, 27.1300192],
  [88.1811347, 27.1323083],
  [88.182628, 27.1322532],
  [88.1847199, 27.131407],
  [88.1856969, 27.1321171],
  [88.1870019, 27.1330655],
  [88.1919843, 27.1324901],
  [88.1970428, 27.1330655],
  [88.2012265, 27.1309331],
  [88.2032423, 27.1312377],
  [88.2047637, 27.1326255],
  [88.207426, 27.1324901],
  [88.2100884, 27.1304253],
  [88.2142881, 27.1317051],
  [88.2162871, 27.1311041],
  [88.2168584, 27.1309331],
  [88.2286848, 27.1192146],
  [88.2319578, 27.1188484],
  [88.2387278, 27.1185776],
  [88.2453837, 27.1200671],
  [88.2475897, 27.1220305],
  [88.2508225, 27.1233507],
  [88.2555387, 27.1237569],
  [88.2582771, 27.1239939],
  [88.2626023, 27.1274257],
  [88.264895, 27.1272773],
  [88.2674433, 27.1287667],
  [88.2719312, 27.1277851],
  [88.2775602, 27.1295453],
  [88.2783956, 27.1311338],
  [88.2820395, 27.1277214],
  [88.2851647, 27.1266398],
  [88.2891232, 27.1272525],
  [88.2921912, 27.126949],
  [88.2945889, 27.1260345],
  [88.2965132, 27.1255674],
  [88.3003044, 27.1275612],
  [88.3018301, 27.1288547],
  [88.3038622, 27.1280136],
  [88.3071072, 27.1257336],
  [88.3102209, 27.1246558],
  [88.3136652, 27.1246558],
  [88.3156526, 27.1245085],
  [88.3156526, 27.1214787],
  [88.3177457, 27.1202367],
  [88.3210556, 27.1188711],
  [88.3226907, 27.1172013],
  [88.3226907, 27.1149471],
  [88.3223477, 27.1105202],
  [88.3218324, 27.1068506],
  [88.3218324, 27.1053766],
  [88.3247178, 27.1047889],
  [88.326899, 27.1069318],
  [88.3315056, 27.1069318],
  [88.3339145, 27.1060129],
  [88.3361999, 27.1063258],
  [88.3384305, 27.1092277],
  [88.3414572, 27.1095271],
  [88.3448804, 27.1073687],
  [88.3455422, 27.1069514],
  [88.3469058, 27.1051305],
  [88.3487967, 27.1020702],
  [88.3501943, 27.1006705],
  [88.3528021, 27.100361],
  [88.3550135, 27.100361],
  [88.357023, 27.0990193],
  [88.357023, 27.0956686],
  [88.357023, 27.0951651],
  [88.3600225, 27.0951651],
  [88.3646563, 27.0957763],
  [88.370833, 27.0956235],
  [88.3789047, 27.0960823],
  [88.3838603, 27.0963865],
  [88.3890004, 27.0937934],
  [88.3929427, 27.0930305],
  [88.3961967, 27.0907434],
  [88.398278, 27.089817],
  [88.4024104, 27.0896636],
  [88.4072055, 27.0890537],
  [88.4138925, 27.0869166],
  [88.4193797, 27.0850846],
  [88.4248629, 27.0823385],
  [88.4264213, 27.0812593],
  [88.4307122, 27.0809537],
  [88.4326961, 27.0793969],
  [88.4343646, 27.080697],
  [88.4372731, 27.0822203],
  [88.4419013, 27.0828308],
  [88.4449933, 27.0828308],
  [88.4498077, 27.0842086],
  [88.4535881, 27.0868094],
  [88.4565067, 27.0889494],
  [88.4591517, 27.090908],
  [88.4596021, 27.0912458],
  [88.4611499, 27.0952266],
  [88.4620067, 27.0990402],
  [88.4647474, 27.1008701],
  [88.4664113, 27.1024967],
  [88.4665835, 27.1047974],
  [88.4665835, 27.1087688],
  [88.4674413, 27.1124333],
  [88.468298, 27.1154833],
  [88.4698347, 27.1170032],
  [88.4724095, 27.1179199],
  [88.4746804, 27.1201175],
  [88.4760181, 27.1212848],
  [88.4772129, 27.1225],
  [88.4801111, 27.1225],
  [88.4830283, 27.1205145],
  [88.4855286, 27.1196094],
  [88.4856372, 27.1225168],
  [88.4863209, 27.1251031],
  [88.4885319, 27.1258599],
  [88.4907519, 27.125252],
  [88.4931554, 27.1238769],
  [88.4955691, 27.123263],
  [88.4979824, 27.1238768],
  [88.5000662, 27.1249586],
  [88.4990233, 27.1266603],
  [88.4981743, 27.127567],
  [88.4993652, 27.1295352],
  [88.5012531, 27.1307573],
  [88.5026293, 27.1329005],
  [88.5032267, 27.1338974],
  [88.5040004, 27.1351884],
  [88.5058748, 27.1365531],
  [88.5084413, 27.135944],
  [88.5106919, 27.1360981],
  [88.5119001, 27.1384019],
  [88.512755, 27.1402277],
  [88.5144579, 27.1409854],
  [88.51618, 27.1409854],
  [88.5173929, 27.1422191],
  [88.5182526, 27.144973],
  [88.5189361, 27.1467977],
  [88.5211544, 27.1480125],
  [88.5245822, 27.148165],
  [88.5283588, 27.1486232],
  [88.5316237, 27.1487761],
  [88.5336989, 27.1495456],
  [88.5345624, 27.1523112],
  [88.5338722, 27.1547677],
  [88.5312861, 27.156762],
  [88.5285563, 27.1572174],
  [88.5288959, 27.1594838],
  [88.5318175, 27.1642218],
  [88.5311223, 27.1660771],
  [88.5285393, 27.1665367],
  [88.5254552, 27.1688232],
  [88.5228851, 27.1711098],
  [88.5219864, 27.1733102],
  [88.5244003, 27.1737426],
  [88.5280237, 27.1740495],
  [88.5311137, 27.1726751],
  [88.5329612, 27.1728245],
  [88.5343011, 27.1744635],
  [88.5332771, 27.1765889],
  [88.5336304, 27.1784746],
  [88.5366009, 27.17863],
  [88.5388067, 27.1781772],
  [88.5403235, 27.1798265],
  [88.5415286, 27.1828893],
  [88.5441414, 27.1842838],
  [88.5477693, 27.1833618],
  [88.5508326, 27.1826048],
  [88.5535619, 27.1844256],
  [88.5568363, 27.1858054],
  [88.5608114, 27.1862666],
  [88.5623674, 27.1854976],
  [88.5650908, 27.1847406],
  [88.5674721, 27.1854971],
  [88.5702192, 27.1868716],
  [88.5738366, 27.1879442],
  [88.5781307, 27.188097],
  [88.5810519, 27.1887084],
  [88.5836119, 27.1885566],
  [88.5851515, 27.1897738],
  [88.5872795, 27.1900893],
  [88.5877992, 27.1879319],
  [88.5891038, 27.1874967],
  [88.59028, 27.1889914],
  [88.5915032, 27.1907012],
  [88.5939666, 27.1900752],
  [88.5951548, 27.1884143],
  [88.5968162, 27.1890054],
  [88.5978171, 27.1901047],
  [88.5996723, 27.1891052],
  [88.6007715, 27.1859658],
  [88.6051636, 27.1858528],
  [88.6107898, 27.1837673],
  [88.6185594, 27.1832676],
  [88.637458, 27.1687982],
  [88.6385487, 27.1676826],
  [88.6563891, 27.1623304],
  [88.6733376, 27.1766028],
  [88.691624, 27.1788328],
  [88.7150717, 27.1622662],
  [88.7202643, 27.1440135],
  [88.7246289, 27.1386918],
  [88.7464856, 27.1418502],
  [88.7464867, 27.1429656],
  [88.74663, 27.1436532],
  [88.7472316, 27.1448279],
  [88.7481484, 27.1458879],
  [88.7489506, 27.1466614],
  [88.7495235, 27.1475782],
  [88.7498387, 27.1482658],
  [88.7503257, 27.1488674],
  [88.7508701, 27.1494691],
  [88.751529, 27.150128],
  [88.7519874, 27.1505291],
  [88.7525317, 27.1506437],
  [88.7537064, 27.1506151],
  [88.7547664, 27.1504432],
  [88.7556259, 27.1504432],
  [88.7563994, 27.1505578],
  [88.7570011, 27.1508156],
  [88.75766, 27.1512167],
  [88.7585481, 27.1519902],
  [88.7590065, 27.1525059],
  [88.7596082, 27.1527638],
  [88.7600952, 27.152993],
  [88.7601525, 27.1542535],
  [88.7600093, 27.1547119],
  [88.7594936, 27.155199],
  [88.7593503, 27.155772],
  [88.7590925, 27.1564309],
  [88.7587773, 27.1568606],
  [88.7582903, 27.1575482],
  [88.7579178, 27.1578347],
  [88.7574595, 27.1580353],
  [88.7569724, 27.1582645],
  [88.7567719, 27.1586083],
  [88.7569724, 27.1594391],
  [88.7570011, 27.160184],
  [88.7570297, 27.1607283],
  [88.757087, 27.1616737],
  [88.7571157, 27.1623327],
  [88.7573449, 27.1629916],
  [88.7576027, 27.1635646],
  [88.7576314, 27.1641949],
  [88.7574881, 27.1647392],
  [88.7573162, 27.1652549],
  [88.7585195, 27.1664869],
  [88.7593217, 27.1674323],
  [88.7596368, 27.1682918],
  [88.7597648, 27.1689864],
  [88.7598242, 27.1693805],
  [88.7602826, 27.1703545],
  [88.7605404, 27.1711567],
  [88.7607982, 27.1715292],
  [88.7610847, 27.1722168],
  [88.7614285, 27.172446],
  [88.7622307, 27.1726751],
  [88.7627178, 27.1732195],
  [88.7633194, 27.1742509],
  [88.7636918, 27.1749385],
  [88.7642362, 27.1756547],
  [88.7644367, 27.1761131],
  [88.764494, 27.1766574],
  [88.7646946, 27.1771445],
  [88.765067, 27.1777748],
  [88.7656973, 27.1783764],
  [88.7664708, 27.1791213],
  [88.7672444, 27.1794078],
  [88.7679606, 27.1796943],
  [88.7684763, 27.1801527],
  [88.7687628, 27.1805538],
  [88.7701666, 27.1811267],
  [88.7713699, 27.1816711],
  [88.772344, 27.1820435],
  [88.7730316, 27.1822154],
  [88.7741775, 27.1829603],
  [88.775123, 27.1818716],
  [88.77561, 27.181757],
  [88.7761257, 27.1818716],
  [88.776756, 27.1822441],
  [88.7774722, 27.1828744],
  [88.7779593, 27.1834474],
  [88.7785323, 27.1837911],
  [88.7794204, 27.1840203],
  [88.7803372, 27.1842495],
  [88.7813686, 27.1844501],
  [88.7819129, 27.1844501],
  [88.7824572, 27.1838771],
  [88.7826578, 27.1833328],
  [88.7829156, 27.1828457],
  [88.783947, 27.1828457],
  [88.7848638, 27.182903],
  [88.7853725, 27.182903],
  [88.7856183, 27.1829271],
  [88.786153, 27.1827598],
  [88.7866974, 27.1827025],
  [88.7870698, 27.182989],
  [88.7874673, 27.1837027],
  [88.7876428, 27.1840203],
  [88.7879006, 27.1843355],
  [88.788359, 27.184536],
  [88.7888174, 27.1848512],
  [88.7890753, 27.1853096],
  [88.7894477, 27.1861404],
  [88.7896196, 27.1866274],
  [88.7899634, 27.1877161],
  [88.790164, 27.1887762],
  [88.7903347, 27.18958],
  [88.7903932, 27.1904951],
  [88.7898202, 27.1910968],
  [88.7891612, 27.1916125],
  [88.7888747, 27.1920708],
  [88.7888174, 27.1928157],
  [88.7890753, 27.1938185],
  [88.7896769, 27.1945347],
  [88.7902499, 27.1953369],
  [88.7907656, 27.1961391],
  [88.7910807, 27.1967694],
  [88.7916537, 27.1971705],
  [88.7921981, 27.1978867],
  [88.7926278, 27.198517],
  [88.7928857, 27.1991473],
  [88.7934873, 27.2000067],
  [88.7936592, 27.2005797],
  [88.7938024, 27.2012673],
  [88.7943181, 27.2018117],
  [88.7948338, 27.2020982],
  [88.7953782, 27.2024706],
  [88.7960085, 27.2031868],
  [88.7961505, 27.2035436],
  [88.7960462, 27.2046728],
  [88.7961395, 27.2049131],
  [88.7961505, 27.2049855],
  [88.7967933, 27.2056804],
  [88.7975229, 27.2060279],
  [88.7979572, 27.2064274],
  [88.7981657, 27.2070007],
  [88.7986174, 27.2075566],
  [88.7990864, 27.2080257],
  [88.7994686, 27.20846],
  [88.7997466, 27.2091028],
  [88.7999377, 27.2100236],
  [88.7998669, 27.2109641],
  [88.799974, 27.2118564],
  [88.8001525, 27.2126059],
  [88.8004737, 27.2132127],
  [88.800902, 27.2140693],
  [88.8011518, 27.2148188],
  [88.8012589, 27.2154256],
  [88.8014017, 27.2163179],
  [88.8016515, 27.2171745],
  [88.8022583, 27.2178883],
  [88.8027937, 27.2186021],
  [88.8030316, 27.2190959],
  [88.803222, 27.2200477],
  [88.8032458, 27.2207139],
  [88.8030078, 27.2217609],
  [88.8030316, 27.2223082],
  [88.8031506, 27.2230934],
  [88.8031506, 27.2235931],
  [88.8029126, 27.2242355],
  [88.8025319, 27.2248066],
  [88.8023654, 27.2253777],
  [88.8022226, 27.2261867],
  [88.802056, 27.2268529],
  [88.8019609, 27.2275668],
  [88.8020084, 27.2283044],
  [88.8019371, 27.2286851],
  [88.8015326, 27.2291848],
  [88.8013184, 27.2294941],
  [88.8016515, 27.2300176],
  [88.8019609, 27.2304935],
  [88.8022226, 27.2310646],
  [88.8024367, 27.2318498],
  [88.8026747, 27.2325636],
  [88.8029602, 27.2330871],
  [88.8031506, 27.2337772],
  [88.8034123, 27.2346338],
  [88.8037217, 27.2350621],
  [88.8039834, 27.2353714],
  [88.8042927, 27.2356807],
  [88.8044593, 27.2358235],
  [88.8043641, 27.2368942],
  [88.8040786, 27.2375367],
  [88.8039834, 27.2382267],
  [88.804031, 27.2393451],
  [88.8041024, 27.2401065],
  [88.8039834, 27.2411773],
  [88.8036027, 27.2424384],
  [88.8033647, 27.2431998],
  [88.8032696, 27.2441278],
  [88.8032934, 27.2452699],
  [88.8030792, 27.2461028],
  [88.8025319, 27.2474353],
  [88.8022839, 27.2483656],
  [88.8265465, 27.2568286],
  [88.8308688, 27.264185],
  [88.8644247, 27.2650727],
  [88.8792125, 27.2627246],
  [88.8797111, 27.2630681],
  [88.8806859, 27.2639127],
  [88.8823991, 27.2655783],
  [88.883446, 27.2661494],
  [88.8843978, 27.2665539],
  [88.8859445, 27.2673153],
  [88.8868963, 27.2675532],
  [88.8878718, 27.2679578],
  [88.889014, 27.2682671],
  [88.8898706, 27.268624],
  [88.890632, 27.2682909],
  [88.8915217, 27.2686738],
  [88.8920842, 27.2689138],
  [88.8927143, 27.2691539],
  [88.8932468, 27.2692739],
  [88.8938244, 27.2694389],
  [88.8942294, 27.2696189],
  [88.8949645, 27.2701215],
  [88.8954145, 27.2704365],
  [88.8957296, 27.2705115],
  [88.8963671, 27.2705115],
  [88.8976573, 27.270384],
  [88.8987749, 27.270834],
  [88.8988574, 27.2715391],
  [88.8989924, 27.2726342],
  [88.8991124, 27.2732568],
  [88.8993674, 27.2736843],
  [88.899675, 27.2738793],
  [88.900035, 27.2740218],
  [88.9001775, 27.2740218],
  [88.9006594, 27.2738815],
  [88.9013405, 27.2736999],
  [88.902348, 27.2730588],
  [88.9024334, 27.2730316],
  [88.9030526, 27.272841],
  [88.9033285, 27.2727753],
  [88.9038278, 27.2728541],
  [88.9044893, 27.2729728],
  [88.904936, 27.2734983],
  [88.9054353, 27.2739713],
  [88.9055565, 27.274088],
  [88.9059739, 27.2741684],
  [88.9062893, 27.2743654],
  [88.9066308, 27.2747727],
  [88.9070907, 27.2752326],
  [88.9074585, 27.2756004],
  [88.9077739, 27.2758895],
  [88.9079578, 27.2762311],
  [88.9082468, 27.2768223],
  [88.9085833, 27.2773454],
  [88.90893, 27.2781098],
  [88.9091523, 27.2787121],
  [88.9090745, 27.2796995],
  [88.90893, 27.2801199],
  [88.9087461, 27.2804747],
  [88.9086278, 27.2807506],
  [88.9086016, 27.2809871],
  [88.908641, 27.2816046],
  [88.9087461, 27.2819593],
  [88.909665, 27.282982],
  [88.9103095, 27.2841796],
  [88.9105723, 27.2844161],
  [88.9107299, 27.2847051],
  [88.9110978, 27.2849942],
  [88.9116365, 27.2857825],
  [88.912254, 27.2868467],
  [88.9126074, 27.2874189],
  [88.9127598, 27.2877297],
  [88.9131976, 27.2885645],
  [88.9135045, 27.2892163],
  [88.9136408, 27.2896378],
  [88.9137397, 27.2907451],
  [88.9137152, 27.2910444],
  [88.913684, 27.2914256],
  [88.9135538, 27.2922998],
  [88.9132036, 27.2934937],
  [88.9128487, 27.2947134],
  [88.9124087, 27.2957493],
  [88.9117622, 27.2972857],
  [88.9114354, 27.2979339],
  [88.9112665, 27.2982689],
  [88.9110615, 27.2985684],
  [88.9105942, 27.2991507],
  [88.909687, 27.2998466],
  [88.9091779, 27.3002296],
  [88.908823, 27.3006433],
  [88.908323, 27.3011374],
  [88.9078749, 27.3015539],
  [88.9077379, 27.3024671],
  [88.9078233, 27.3030654],
  [88.9080527, 27.3036523],
  [88.9083108, 27.3044476],
  [88.9088763, 27.3047929],
  [88.9092036, 27.3051312],
  [88.9094884, 27.3053208],
  [88.9100457, 27.3054431],
  [88.9105251, 27.3056747],
  [88.9109052, 27.3061277],
  [88.9115596, 27.3063392],
  [88.9116909, 27.3064561],
  [88.9119124, 27.3066064],
  [88.9120175, 27.3066458],
  [88.9121094, 27.3066852],
  [88.912162, 27.3067772],
  [88.9122573, 27.3068573],
  [88.9123873, 27.3069743],
  [88.9126534, 27.3071222],
  [88.9129047, 27.3072735],
  [88.9134262, 27.3084076],
  [88.9138437, 27.3090764],
  [88.9139383, 27.30927],
  [88.9144088, 27.3101041],
  [88.9145139, 27.3103908],
  [88.9145979, 27.3110524],
  [88.9146404, 27.3113323],
  [88.9146363, 27.3117282],
  [88.91476, 27.3118324],
  [88.9148033, 27.3119867],
  [88.9149592, 27.3123145],
  [88.9151039, 27.3126207],
  [88.9154334, 27.3131229],
  [88.9155911, 27.3135828],
  [88.9156391, 27.3137557],
  [88.9157162, 27.314056],
  [88.9158586, 27.3144002],
  [88.9159548, 27.3152349],
  [88.9161097, 27.315385],
  [88.9163077, 27.3159984],
  [88.9161857, 27.3166826],
  [88.91625, 27.3168244],
  [88.9164443, 27.3169238],
  [88.9170782, 27.3170492],
  [88.9176845, 27.3172165],
  [88.9179248, 27.3174145],
  [88.9182511, 27.3175081],
  [88.9183463, 27.3178507],
  [88.9187806, 27.3180282],
  [88.9190172, 27.3184026],
  [88.9190803, 27.3185319],
  [88.9191759, 27.3187217],
  [88.9193547, 27.3188602],
  [88.9196129, 27.3190084],
  [88.9195807, 27.3192295],
  [88.9194583, 27.3195794],
  [88.9195398, 27.3198561],
  [88.9196419, 27.3201381],
  [88.9196911, 27.3203486],
  [88.919676, 27.3205344],
  [88.9196853, 27.3207582],
  [88.9197277, 27.3210664],
  [88.9197548, 27.321296],
  [88.9197701, 27.321485],
  [88.9198217, 27.3217944],
  [88.9197766, 27.3220619],
  [88.9196432, 27.3224604],
  [88.9196213, 27.322686],
  [88.9195901, 27.3232659],
  [88.919589, 27.325394],
  [88.9198265, 27.3255757],
  [88.9198133, 27.3257025],
  [88.9198821, 27.3258113],
  [88.9201175, 27.3258895],
  [88.9209866, 27.3262184],
  [88.9212536, 27.3268785],
  [88.9213678, 27.3272875],
  [88.9210996, 27.3274631],
  [88.9209443, 27.3276136],
  [88.9203649, 27.3277638],
  [88.920086, 27.3279999],
  [88.9200001, 27.3284076],
  [88.9194207, 27.3289226],
  [88.9189486, 27.3296522],
  [88.9177684, 27.3301243],
  [88.9168885, 27.3305535],
  [88.9157941, 27.3310256],
  [88.9156549, 27.3309776],
  [88.9154276, 27.3308322],
  [88.9147481, 27.3305302],
  [88.9139674, 27.3300153],
  [88.9133733, 27.329952],
  [88.912217, 27.3297947],
  [88.9114128, 27.3293046],
  [88.9105799, 27.3292925],
  [88.9103114, 27.3291168],
  [88.9094733, 27.3289197],
  [88.908904, 27.3288746],
  [88.9076905, 27.3290004],
  [88.9066417, 27.3288],
  [88.9059606, 27.3288366],
  [88.9056917, 27.3290524],
  [88.9054094, 27.329536],
  [88.9045852, 27.330398],
  [88.9035409, 27.3310875],
  [88.9022194, 27.3319781],
  [88.900668, 27.3326389],
  [88.8990592, 27.3330411],
  [88.8977377, 27.3329836],
  [88.8956405, 27.3326963],
  [88.895066, 27.3329549],
  [88.8944914, 27.3339604],
  [88.8942615, 27.334851],
  [88.8938306, 27.3365747],
  [88.8933281, 27.3384239],
  [88.8926565, 27.3391174],
  [88.8910727, 27.3399072],
  [88.8897511, 27.3407116],
  [88.8882572, 27.3413149],
  [88.8869932, 27.3418033],
  [88.8857941, 27.3421955],
  [88.8848098, 27.3430099],
  [88.8834308, 27.3443602],
  [88.8829074, 27.3454543],
  [88.8825088, 27.3462212],
  [88.882569, 27.3468021],
  [88.8828563, 27.3473767],
  [88.8835745, 27.3481811],
  [88.8844363, 27.3492153],
  [88.8846949, 27.3502496],
  [88.8847236, 27.3507667],
  [88.8836032, 27.3507954],
  [88.8824828, 27.3510827],
  [88.8816784, 27.3518584],
  [88.8812474, 27.3528639],
  [88.8808165, 27.353697],
  [88.8809602, 27.3538406],
  [88.8815347, 27.3550185],
  [88.8820231, 27.3558804],
  [88.8823391, 27.3568284],
  [88.8823104, 27.3574892],
  [88.8820806, 27.3583511],
  [88.8816496, 27.3596438],
  [88.8813624, 27.3605344],
  [88.8811325, 27.3605632],
  [88.8801845, 27.360477],
  [88.8793226, 27.3603621],
  [88.8783458, 27.3605632],
  [88.8775702, 27.360793],
  [88.8766796, 27.3607643],
  [88.8762486, 27.3609654],
  [88.8758464, 27.3613676],
  [88.8755304, 27.3622582],
  [88.8750133, 27.3629189],
  [88.8741515, 27.3634935],
  [88.8726001, 27.3644128],
  [88.8719393, 27.3644128],
  [88.8709051, 27.364183],
  [88.8704167, 27.3641255],
  [88.8699283, 27.3643554],
  [88.8690665, 27.3648725],
  [88.8682333, 27.3655045],
  [88.8674577, 27.3662514],
  [88.8660798, 27.3666257],
  [88.8658678, 27.3668127],
  [88.8650526, 27.3675318],
  [88.8648807, 27.3683929],
  [88.863647, 27.3692243],
  [88.8630199, 27.3701298],
  [88.8633387, 27.371903],
  [88.8638909, 27.3735727],
  [88.8631782, 27.3743906],
  [88.8630622, 27.3747839],
  [88.8631771, 27.3758468],
  [88.8635506, 27.3765363],
  [88.8640585, 27.3776831],
  [88.8644394, 27.3780489],
  [88.8645561, 27.3787197],
  [88.8644986, 27.3797827],
  [88.8639434, 27.3803941],
  [88.8631168, 27.3808836],
  [88.8628469, 27.3810609],
  [88.8627126, 27.3811597],
  [88.8618843, 27.3818224],
  [88.8615396, 27.3829141],
  [88.861281, 27.3851549],
  [88.8611373, 27.3863041],
  [88.8603697, 27.3860334],
  [88.8600571, 27.3856891],
  [88.8589252, 27.3854997],
  [88.8576037, 27.3850687],
  [88.8561673, 27.3848964],
  [88.8546447, 27.3848964],
  [88.8532657, 27.3849538],
  [88.8511685, 27.3846665],
  [88.8498757, 27.3845516],
  [88.8489851, 27.3849826],
  [88.8480658, 27.3852986],
  [88.8466868, 27.3852124],
  [88.84571, 27.3850113],
  [88.8445322, 27.3842643],
  [88.8435267, 27.3839196],
  [88.8426361, 27.3831727],
  [88.842004, 27.3823683],
  [88.8407687, 27.3811616],
  [88.8397057, 27.3804147],
  [88.8386428, 27.3799263],
  [88.8378958, 27.3796103],
  [88.8377809, 27.3805296],
  [88.8377522, 27.3813915],
  [88.8374936, 27.382081],
  [88.8368616, 27.3826555],
  [88.8355113, 27.3830003],
  [88.8350804, 27.383345],
  [88.8342717, 27.3838906],
  [88.8332444, 27.3846551],
  [88.8325523, 27.38504],
  [88.8318081, 27.3854795],
  [88.8314394, 27.3856041],
  [88.8312775, 27.3856588],
  [88.8309312, 27.3865975],
  [88.8308961, 27.3868289],
  [88.8308779, 27.3869488],
  [88.8309598, 27.3877793],
  [88.8311733, 27.388746],
  [88.8311733, 27.3894068],
  [88.8308286, 27.390125],
  [88.8298231, 27.3905559],
  [88.8285877, 27.3906996],
  [88.8267204, 27.3905272],
  [88.8260021, 27.3904697],
  [88.8249679, 27.3913316],
  [88.8238742, 27.3924233],
  [88.8223249, 27.3930266],
  [88.8216354, 27.3937735],
  [88.8214343, 27.394463],
  [88.8214343, 27.3952674],
  [88.8212332, 27.3958995],
  [88.8210608, 27.3971061],
  [88.8208022, 27.3983989],
  [88.8200553, 27.3987436],
  [88.8199691, 27.4002088],
  [88.8199691, 27.4009845],
  [88.8200266, 27.4016739],
  [88.8203139, 27.402306],
  [88.8207161, 27.4033977],
  [88.8210895, 27.4046617],
  [88.8199978, 27.4046905],
  [88.8192509, 27.4048341],
  [88.8181879, 27.4052363],
  [88.8172112, 27.4052938],
  [88.8158322, 27.4050639],
  [88.8142521, 27.4049203],
  [88.8130168, 27.404949],
  [88.81204, 27.4048341],
  [88.8109483, 27.4047479],
  [88.8099141, 27.404633],
  [88.8085925, 27.4044032],
  [88.807702, 27.4044606],
  [88.8067539, 27.4054374],
  [88.8056909, 27.4070462],
  [88.8058346, 27.4080517],
  [88.8061219, 27.4087412],
  [88.8066964, 27.409718],
  [88.8070412, 27.4111831],
  [88.8068114, 27.4123036],
  [88.8059208, 27.4133378],
  [88.8053749, 27.4143433],
  [88.8046754, 27.4151047],
  [88.8052672, 27.41605],
  [88.8059262, 27.417161],
  [88.806284, 27.4182344],
  [88.806736, 27.419402],
  [88.806962, 27.4204377],
  [88.8076022, 27.4220007],
  [88.8082425, 27.4231494],
  [88.8070373, 27.423639],
  [88.8066042, 27.4236013],
  [88.8057003, 27.4229611],
  [88.8048905, 27.4226033],
  [88.8041184, 27.4225091],
  [88.803384, 27.4227351],
  [88.8027061, 27.4228104],
  [88.8018963, 27.4227163],
  [88.8011808, 27.4227728],
  [88.8006911, 27.4232247],
  [88.800258, 27.4242981],
  [88.7995048, 27.425428],
  [88.7996178, 27.4258988],
  [88.7995613, 27.4264825],
  [88.7997307, 27.4271416],
  [88.7996931, 27.4277631],
  [88.799373, 27.428102],
  [88.7989963, 27.4281774],
  [88.7983372, 27.4280267],
  [88.7974333, 27.4277066],
  [88.796247, 27.4273488],
  [88.7959268, 27.4278384],
  [88.7956255, 27.4283092],
  [88.7951924, 27.4287611],
  [88.7947216, 27.4290813],
  [88.7945898, 27.4292884],
  [88.7944203, 27.4297027],
  [88.7943262, 27.4302488],
  [88.794345, 27.4308891],
  [88.7943262, 27.4316423],
  [88.7942885, 27.4322261],
  [88.7941378, 27.432791],
  [88.7939495, 27.4330923],
  [88.7937989, 27.4335631],
  [88.7935729, 27.4341657],
  [88.7933658, 27.4345047],
  [88.7931963, 27.4352768],
  [88.7931021, 27.4356534],
  [88.7926878, 27.4359924],
  [88.7923112, 27.4365385],
  [88.7915203, 27.4371976],
  [88.7914638, 27.43748],
  [88.7916333, 27.4380261],
  [88.7919722, 27.4384593],
  [88.7924807, 27.4387041],
  [88.7930268, 27.4390054],
  [88.7934976, 27.4396833],
  [88.7937989, 27.4406437],
  [88.7939307, 27.4418489],
  [88.7939684, 27.4432047],
  [88.7938177, 27.4449372],
  [88.7934784, 27.4471589],
  [88.7933281, 27.4471405],
  [88.79233, 27.4470087],
  [88.7918028, 27.4470463],
  [88.790993, 27.4471782],
  [88.7897125, 27.44731],
  [88.7890534, 27.4473288],
  [88.7886956, 27.4475171],
  [88.7881307, 27.4475171],
  [88.7879423, 27.4482139],
  [88.7879047, 27.4490048],
  [88.7878858, 27.4501535],
  [88.7877729, 27.4507184],
  [88.7872644, 27.4511892],
  [88.7856638, 27.4527711],
  [88.7851553, 27.4530347],
  [88.7844021, 27.4532042],
  [88.7836865, 27.4533737],
  [88.7828391, 27.453449],
  [88.7826696, 27.4539951],
  [88.7826319, 27.4545412],
  [88.7824813, 27.4554451],
  [88.7823784, 27.4559614],
  [88.7824099, 27.4564331],
  [88.7825042, 27.4568261],
  [88.7826929, 27.4576594],
  [88.7826446, 27.4580988],
  [88.7822028, 27.4591298],
  [88.7812454, 27.4604554],
  [88.7808036, 27.4614127],
  [88.7800671, 27.4624437],
  [88.7791098, 27.4628119],
  [88.7788889, 27.463622],
  [88.7789625, 27.464653],
  [88.778447, 27.4655367],
  [88.7782261, 27.4665677],
  [88.7780052, 27.4676723],
  [88.7771951, 27.4684088],
  [88.7768269, 27.4692925],
  [88.7771215, 27.4704707],
  [88.7780052, 27.4715017],
  [88.7785531, 27.4720936],
  [88.7792905, 27.4726145],
  [88.779879, 27.473105],
  [88.7805067, 27.4735169],
  [88.7810364, 27.4737523],
  [88.7814091, 27.4740466],
  [88.7818799, 27.4744586],
  [88.78239, 27.4747332],
  [88.782645, 27.4748901],
  [88.7826646, 27.4751452],
  [88.782645, 27.4755964],
  [88.7825469, 27.4761064],
  [88.7821546, 27.4764791],
  [88.7820173, 27.4766361],
  [88.7820761, 27.4767734],
  [88.7822134, 27.477048],
  [88.7822331, 27.4774011],
  [88.7821154, 27.4777935],
  [88.7819388, 27.478127],
  [88.7819584, 27.4783035],
  [88.7819976, 27.4786567],
  [88.7819192, 27.4789509],
  [88.781468, 27.4794806],
  [88.7810364, 27.4801476],
  [88.7805852, 27.4809519],
  [88.7804871, 27.4814619],
  [88.7808402, 27.481972],
  [88.7811541, 27.4823839],
  [88.7817034, 27.4828351],
  [88.7821938, 27.4831686],
  [88.7828804, 27.4835217],
  [88.7831747, 27.4837571],
  [88.7834689, 27.4843653],
  [88.7838417, 27.4848361],
  [88.7842144, 27.4853265],
  [88.7843517, 27.4856404],
  [88.7843517, 27.4858562],
  [88.7843321, 27.4868763],
  [88.7843321, 27.4874256],
  [88.7844694, 27.4877395],
  [88.7847048, 27.4881122],
  [88.784391, 27.4886222],
  [88.7840378, 27.4893677],
  [88.7839986, 27.4899954],
  [88.7839005, 27.4905055],
  [88.7836847, 27.4910744],
  [88.7833316, 27.4917806],
  [88.7834689, 27.4921337],
  [88.7836259, 27.492683],
  [88.7837436, 27.4934285],
  [88.7838024, 27.4938012],
  [88.7837632, 27.4942132],
  [88.7836651, 27.4945663],
  [88.7835082, 27.4948017],
  [88.7832924, 27.4949586],
  [88.7832335, 27.4952725],
  [88.7830374, 27.4953313],
  [88.7827627, 27.4955079],
  [88.7824685, 27.4959199],
  [88.7824292, 27.4961945],
  [88.7824685, 27.4964299],
  [88.7831943, 27.4966653],
  [88.783567, 27.4968811],
  [88.7836847, 27.4975873],
  [88.7836259, 27.4990586],
  [88.7833905, 27.5001964],
  [88.7833905, 27.5008634],
  [88.7836063, 27.5011577],
  [88.7839594, 27.50155],
  [88.7845087, 27.5019424],
  [88.7850383, 27.5021582],
  [88.7859015, 27.502472],
  [88.7862154, 27.502629],
  [88.7864312, 27.5028644],
  [88.7867058, 27.5032371],
  [88.7868039, 27.5035902],
  [88.7868039, 27.5039237],
  [88.7866077, 27.5041787],
  [88.7864115, 27.5045515],
  [88.7862546, 27.5050419],
  [88.7861369, 27.5056108],
  [88.7859407, 27.5061601],
  [88.7858426, 27.5065328],
  [88.7858426, 27.5067878],
  [88.7859996, 27.5070232],
  [88.7861369, 27.5072979],
  [88.7863919, 27.5081218],
  [88.7866077, 27.5091027],
  [88.7867843, 27.5099658],
  [88.7871178, 27.5107898],
  [88.7872747, 27.5111429],
  [88.7876082, 27.5114764],
  [88.7880986, 27.5117902],
  [88.7886871, 27.5121826],
  [88.7891776, 27.5124965],
  [88.7895503, 27.5127711],
  [88.7898446, 27.5133596],
  [88.7899819, 27.5139874],
  [88.7902369, 27.5147721],
  [88.7904527, 27.5157137],
  [88.7896876, 27.5165572],
  [88.7893737, 27.5171261],
  [88.7892168, 27.5177735],
  [88.789158, 27.518362],
  [88.7891187, 27.519186],
  [88.7889618, 27.5201472],
  [88.788746, 27.5208338],
  [88.7884125, 27.5215597],
  [88.7882359, 27.5220108],
  [88.7893345, 27.5225601],
  [88.7897269, 27.5228936],
  [88.7901388, 27.5231487],
  [88.7906489, 27.5233056],
  [88.7912962, 27.5236195],
  [88.7918063, 27.5238156],
  [88.7920809, 27.5240903],
  [88.7921986, 27.5243453],
  [88.7918651, 27.5252477],
  [88.7920809, 27.5253458],
  [88.7924733, 27.5254831],
  [88.7926891, 27.5255616],
  [88.7927479, 27.5257185],
  [88.7928068, 27.5258166],
  [88.7929245, 27.5258951],
  [88.7933364, 27.5262482],
  [88.7935326, 27.5265621],
  [88.7935915, 27.5267582],
  [88.7935718, 27.5269348],
  [88.793101, 27.527229],
  [88.792846, 27.5275822],
  [88.7927675, 27.5279353],
  [88.7927479, 27.5282491],
  [88.7927283, 27.528975],
  [88.792434, 27.530152],
  [88.7917671, 27.5310544],
  [88.7911393, 27.5313683],
  [88.7907273, 27.5315448],
  [88.7902369, 27.5316429],
  [88.7898053, 27.5315645],
  [88.7894915, 27.5314468],
  [88.789001, 27.5311917],
  [88.7883733, 27.5310544],
  [88.7877847, 27.5308582],
  [88.7871962, 27.5306032],
  [88.7868824, 27.5305051],
  [88.7863527, 27.5305444],
  [88.7858819, 27.5306032],
  [88.7854699, 27.5307013],
  [88.7851953, 27.5306425],
  [88.7849599, 27.5304855],
  [88.7847048, 27.5301716],
  [88.7843321, 27.5298774],
  [88.7839986, 27.5296027],
  [88.7837828, 27.5294654],
  [88.7833905, 27.5293869],
  [88.7829785, 27.5294262],
  [88.7826646, 27.5295635],
  [88.7821742, 27.5297793],
  [88.7817819, 27.5300147],
  [88.7812914, 27.530152],
  [88.7809776, 27.5302893],
  [88.7805656, 27.530564],
  [88.7802517, 27.5307405],
  [88.779879, 27.5307994],
  [88.7794866, 27.5307994],
  [88.7790747, 27.5309563],
  [88.7786823, 27.5312506],
  [88.7781919, 27.5316233],
  [88.7775838, 27.5320353],
  [88.7771129, 27.5325649],
  [88.7768383, 27.533075],
  [88.7766814, 27.5336243],
  [88.7763086, 27.5345659],
  [88.7756613, 27.5360176],
  [88.7751512, 27.53692],
  [88.7748177, 27.5376458],
  [88.7744254, 27.5383128],
  [88.7739938, 27.5387444],
  [88.7736603, 27.5391956],
  [88.773366, 27.5401961],
  [88.7733072, 27.5409415],
  [88.7733072, 27.5412946],
  [88.7736799, 27.5418047],
  [88.773778, 27.5421578],
  [88.7738172, 27.542609],
  [88.7738565, 27.5429229],
  [88.7739742, 27.5431387],
  [88.7742881, 27.5434329],
  [88.7747589, 27.5440607],
  [88.7745627, 27.5444923],
  [88.7742096, 27.5450023],
  [88.7739742, 27.5455712],
  [88.773778, 27.5459243],
  [88.7736995, 27.5462186],
  [88.7736603, 27.5466109],
  [88.7736211, 27.5469052],
  [88.7734838, 27.5472583],
  [88.7732483, 27.547631],
  [88.7730326, 27.5481607],
  [88.7729345, 27.5484746],
  [88.7729345, 27.5488473],
  [88.773111, 27.5491808],
  [88.773366, 27.5496712],
  [88.7736407, 27.5500243],
  [88.7737388, 27.5506128],
  [88.7738369, 27.5511817],
  [88.7738369, 27.5515937],
  [88.7737584, 27.5521626],
  [88.7736211, 27.5527707],
  [88.773268, 27.5537516],
  [88.7731503, 27.5542813],
  [88.7730129, 27.5547717],
  [88.7727971, 27.5554191],
  [88.7723459, 27.556243],
  [88.7718555, 27.557165],
  [88.7716593, 27.5576751],
  [88.7714436, 27.5580478],
  [88.7709139, 27.5585775],
  [88.7703842, 27.5589306],
  [88.77009, 27.5591856],
  [88.7695603, 27.5594406],
  [88.769011, 27.5597153],
  [88.7686579, 27.5601468],
  [88.7681086, 27.5606373],
  [88.767834, 27.5608531],
  [88.7675986, 27.5612454],
  [88.7673632, 27.5616181],
  [88.7669512, 27.5619516],
  [88.7665785, 27.5622851],
  [88.7661665, 27.5626971],
  [88.765833, 27.5631091],
  [88.7654995, 27.5633052],
  [88.7653033, 27.5634818],
  [88.7652445, 27.5638349],
  [88.765166, 27.5644038],
  [88.7650483, 27.5646784],
  [88.7633024, 27.5664244],
  [88.7645383, 27.5674445],
  [88.7653622, 27.5682292],
  [88.7660292, 27.5687],
  [88.7665, 27.5692689],
  [88.7669512, 27.5696612],
  [88.7674416, 27.5698966],
  [88.7679909, 27.5701713],
  [88.7684225, 27.5703282],
  [88.7689522, 27.5705244],
  [88.7695407, 27.5708579],
  [88.7702665, 27.5710737],
  [88.7705804, 27.571211],
  [88.7711885, 27.5713679],
  [88.7717574, 27.5714072],
  [88.7723263, 27.5713483],
  [88.7727775, 27.5713287],
  [88.7733072, 27.5712698],
  [88.7737584, 27.5711521],
  [88.7744842, 27.5709756],
  [88.7752101, 27.5708383],
  [88.7757986, 27.5707794],
  [88.7766225, 27.5706617],
  [88.777368, 27.5705244],
  [88.777368, 27.5703282],
  [88.7774072, 27.5701713],
  [88.7776622, 27.5701517],
  [88.778035, 27.5702105],
  [88.7783881, 27.5702105],
  [88.7789962, 27.5700143],
  [88.7796828, 27.5697789],
  [88.7801732, 27.5696024],
  [88.7809187, 27.5694454],
  [88.7812326, 27.5693866],
  [88.7813503, 27.5696808],
  [88.7817622, 27.5705636],
  [88.7822134, 27.5712698],
  [88.7827235, 27.5718191],
  [88.7830177, 27.5721919],
  [88.7840378, 27.5728785],
  [88.7848029, 27.5731139],
  [88.7853326, 27.5732708],
  [88.7859211, 27.5734081],
  [88.786902, 27.5736239],
  [88.7879221, 27.5738201],
  [88.7888048, 27.5740555],
  [88.7895699, 27.5742713],
  [88.7901388, 27.5743694],
  [88.7904135, 27.5745459],
  [88.7906489, 27.5746636],
  [88.7908058, 27.5747029],
  [88.7909627, 27.5746636],
  [88.7911982, 27.5745852],
  [88.7918063, 27.5742321],
  [88.7918651, 27.5744086],
  [88.791924, 27.5749383],
  [88.7918362, 27.5751732],
  [88.7915745, 27.5758394],
  [88.7912413, 27.576696],
  [88.7910272, 27.5771006],
  [88.7910272, 27.5774337],
  [88.7910986, 27.5779096],
  [88.79117, 27.5783141],
  [88.7911938, 27.5787186],
  [88.79117, 27.5792421],
  [88.7906465, 27.5807173],
  [88.7904561, 27.5812646],
  [88.7900992, 27.5817643],
  [88.7895995, 27.5823116],
  [88.789314, 27.5827161],
  [88.7890285, 27.5833823],
  [88.7886477, 27.5837868],
  [88.788505, 27.5841676],
  [88.7884574, 27.5846196],
  [88.7879101, 27.5852859],
  [88.7882194, 27.585857],
  [88.7885288, 27.5862615],
  [88.7888619, 27.586547],
  [88.7893378, 27.5867136],
  [88.7898375, 27.5867612],
  [88.7905275, 27.5866422],
  [88.7913127, 27.5868801],
  [88.7918124, 27.5870943],
  [88.7924311, 27.5876178],
  [88.7926928, 27.5879985],
  [88.7929546, 27.5886409],
  [88.7935256, 27.5892596],
  [88.7941919, 27.5898069],
  [88.7945488, 27.5900686],
  [88.7949533, 27.5905445],
  [88.7953102, 27.5907825],
  [88.7958337, 27.5909252],
  [88.7961906, 27.590949],
  [88.7966189, 27.5912108],
  [88.7973328, 27.5914011],
  [88.7976897, 27.5916391],
  [88.7978563, 27.591877],
  [88.798118, 27.5920912],
  [88.7985939, 27.5921625],
  [88.7989032, 27.5923529],
  [88.7994029, 27.5925195],
  [88.799736, 27.5928526],
  [88.8000454, 27.5929716],
  [88.8005926, 27.5930191],
  [88.801592, 27.5931381],
  [88.8023772, 27.5933999],
  [88.8029721, 27.5936854],
  [88.8036859, 27.5942327],
  [88.8053991, 27.5951131],
  [88.8067078, 27.5967311],
  [88.806922, 27.5980636],
  [88.8071837, 27.5994913],
  [88.8076596, 27.6006572],
  [88.8080641, 27.6015376],
  [88.8085638, 27.6024656],
  [88.8087542, 27.6030605],
  [88.8082545, 27.6034412],
  [88.8077548, 27.6038933],
  [88.8074931, 27.6044168],
  [88.8071837, 27.6054875],
  [88.8069934, 27.6065107],
  [88.8067792, 27.6073911],
  [88.8065889, 27.6079622],
  [88.8062795, 27.6089853],
  [88.8065175, 27.6092471],
  [88.8068982, 27.6095802],
  [88.8071124, 27.6100323],
  [88.8073979, 27.6107699],
  [88.8074931, 27.6114124],
  [88.8075645, 27.6128163],
  [88.8078024, 27.6135301],
  [88.8079452, 27.6136491],
  [88.8085162, 27.6138632],
  [88.8088018, 27.6141726],
  [88.8091349, 27.614934],
  [88.8094442, 27.6157192],
  [88.8097298, 27.6162427],
  [88.8100867, 27.6170041],
  [88.810634, 27.6176704],
  [88.8109433, 27.6183128],
  [88.8111336, 27.6188839],
  [88.8112288, 27.6193598],
  [88.810634, 27.6197643],
  [88.8104198, 27.620026],
  [88.8102057, 27.6204543],
  [88.8097773, 27.6207637],
  [88.8092539, 27.6211444],
  [88.8087304, 27.6214299],
  [88.8083735, 27.6219772],
  [88.8081355, 27.6222627],
  [88.8078262, 27.6223817],
  [88.8072789, 27.6224293],
  [88.8066127, 27.6225483],
  [88.8060178, 27.6226434],
  [88.8055895, 27.6230004],
  [88.805066, 27.6235952],
  [88.804257, 27.6243329],
  [88.8045425, 27.6252846],
  [88.8048281, 27.6260699],
  [88.8049708, 27.6265695],
  [88.805066, 27.627093],
  [88.8052564, 27.6277593],
  [88.8055181, 27.6283066],
  [88.8058274, 27.6287824],
  [88.8062082, 27.6292821],
  [88.8064937, 27.6297818],
  [88.8067316, 27.6301625],
  [88.8072075, 27.6307098],
  [88.8075407, 27.6311381],
  [88.8076834, 27.631614],
  [88.8077548, 27.6321613],
  [88.8077548, 27.6328513],
  [88.8077548, 27.6335652],
  [88.8078024, 27.6340886],
  [88.8080879, 27.6345169],
  [88.8083259, 27.6351118],
  [88.8084449, 27.6356829],
  [88.8087066, 27.6363015],
  [88.8090159, 27.6369202],
  [88.8093253, 27.6372771],
  [88.8117285, 27.6391093],
  [88.8120854, 27.6395138],
  [88.8123472, 27.6398469],
  [88.8124661, 27.6402752],
  [88.8126327, 27.640537],
  [88.8129658, 27.6407987],
  [88.813299, 27.6410367],
  [88.8135369, 27.6414412],
  [88.8137273, 27.6419409],
  [88.8140366, 27.6428451],
  [88.8142507, 27.643892],
  [88.8142983, 27.6442489],
  [88.814279, 27.645605],
  [88.8143221, 27.6459859],
  [88.8148218, 27.6469139],
  [88.8162971, 27.6483892],
  [88.8166064, 27.6483892],
  [88.8168443, 27.6483178],
  [88.8171775, 27.6482226],
  [88.8176296, 27.6482226],
  [88.8180817, 27.6481988],
  [88.8184148, 27.6479371],
  [88.8187241, 27.6476278],
  [88.8190335, 27.6473184],
  [88.8197473, 27.6468901],
  [88.8203897, 27.6463905],
  [88.8210322, 27.6458194],
  [88.8214843, 27.6451293],
  [88.8217222, 27.6446297],
  [88.8218412, 27.6443917],
  [88.8220554, 27.644011],
  [88.8224123, 27.643773],
  [88.8227454, 27.6439158],
  [88.8230547, 27.6439872],
  [88.8233084, 27.6438821],
  [88.8238236, 27.6440431],
  [88.8243388, 27.6442685],
  [88.8247895, 27.6444616],
  [88.8251437, 27.6447836],
  [88.8255945, 27.645331],
  [88.8259809, 27.6457174],
  [88.826657, 27.645975],
  [88.8275908, 27.6461038],
  [88.8282348, 27.6462326],
  [88.8289753, 27.6464257],
  [88.8292973, 27.6465867],
  [88.8300057, 27.6470375],
  [88.830553, 27.6471985],
  [88.8310682, 27.6472951],
  [88.8317444, 27.6472307],
  [88.8318732, 27.6473273],
  [88.832163, 27.6479391],
  [88.8325171, 27.6487118],
  [88.8330323, 27.649549],
  [88.8337085, 27.6503217],
  [88.8343846, 27.6509013],
  [88.8354794, 27.6514809],
  [88.8361234, 27.6518351],
  [88.8363165, 27.6521249],
  [88.8363165, 27.6523824],
  [88.8362522, 27.6529298],
  [88.836059, 27.653445],
  [88.8360912, 27.6539602],
  [88.8364453, 27.6543465],
  [88.8368317, 27.6545397],
  [88.8374113, 27.6547329],
  [88.8377333, 27.6550227],
  [88.8382806, 27.6555379],
  [88.8389246, 27.6561175],
  [88.839472, 27.6567292],
  [88.8395042, 27.6572766],
  [88.8398584, 27.657824],
  [88.8402125, 27.6580172],
  [88.8406955, 27.6584679],
  [88.8414039, 27.6591119],
  [88.8420157, 27.6596915],
  [88.843046, 27.660432],
  [88.8437866, 27.661076],
  [88.8444949, 27.6618488],
  [88.8450101, 27.6625893],
  [88.8456541, 27.6636197],
  [88.846137, 27.664489],
  [88.8464912, 27.6651652],
  [88.8469742, 27.6657126],
  [88.8473284, 27.6661311],
  [88.8476182, 27.6667751],
  [88.8478114, 27.6673869],
  [88.8482299, 27.6681918],
  [88.8485519, 27.6689646],
  [88.8483909, 27.6696408],
  [88.8482943, 27.6705101],
  [88.8482621, 27.6712185],
  [88.8482943, 27.671959],
  [88.8484875, 27.6724742],
  [88.8485841, 27.6727318],
  [88.8484553, 27.6732792],
  [88.8484553, 27.6740841],
  [88.8483909, 27.6749857],
  [88.8482621, 27.6763702],
  [88.847908, 27.676499],
  [88.8475216, 27.67666],
  [88.8467488, 27.6767888],
  [88.8460726, 27.6768854],
  [88.8458151, 27.6770786],
  [88.8452677, 27.6778191],
  [88.8449135, 27.6786885],
  [88.8446559, 27.6795257],
  [88.8443661, 27.6804272],
  [88.8438832, 27.6807492],
  [88.8433358, 27.6811034],
  [88.8426274, 27.6816507],
  [88.842402, 27.6826167],
  [88.8425308, 27.6830997],
  [88.8424664, 27.6832607],
  [88.842563, 27.6840656],
  [88.842724, 27.6849994],
  [88.843046, 27.6861585],
  [88.8434324, 27.6868025],
  [88.8440442, 27.687382],
  [88.8445915, 27.6877362],
  [88.8448169, 27.6881226],
  [88.8454287, 27.6891208],
  [88.8459761, 27.6900867],
  [88.8463624, 27.6912459],
  [88.8471352, 27.6918898],
  [88.8477385, 27.6924625],
  [88.8480717, 27.6929146],
  [88.8482858, 27.6934856],
  [88.8483334, 27.6940329],
  [88.8480717, 27.6945564],
  [88.8479765, 27.6949609],
  [88.8480241, 27.6957461],
  [88.8479527, 27.6961268],
  [88.8477385, 27.6964124],
  [88.8476196, 27.6968883],
  [88.8470961, 27.6974118],
  [88.8469057, 27.6978163],
  [88.8468581, 27.6979828],
  [88.8467868, 27.6984111],
  [88.8465726, 27.6985539],
  [88.8463823, 27.698768],
  [88.8462871, 27.6995057],
  [88.8460967, 27.699815],
  [88.8459064, 27.6999102],
  [88.8456208, 27.7000292],
  [88.8454067, 27.7001957],
  [88.8451449, 27.7001481],
  [88.8448832, 27.7002433],
  [88.8447642, 27.7005526],
  [88.8445263, 27.7007192],
  [88.8439552, 27.7015758],
  [88.8435983, 27.702361],
  [88.8433128, 27.7034318],
  [88.8431938, 27.7036935],
  [88.8426941, 27.7038839],
  [88.8421706, 27.7039791],
  [88.8415282, 27.7039791],
  [88.8409333, 27.7041932],
  [88.8399577, 27.7043122],
  [88.8391011, 27.7045025],
  [88.8387204, 27.7043598],
  [88.8385062, 27.7054067],
  [88.838649, 27.7058113],
  [88.8387918, 27.7062158],
  [88.838887, 27.7068344],
  [88.8390297, 27.7071913],
  [88.8396011, 27.7089251],
  [88.8397198, 27.7092853],
  [88.8398387, 27.7095232],
  [88.8398863, 27.7099991],
  [88.8399577, 27.7103322],
  [88.8402195, 27.7106416],
  [88.8404574, 27.7109271],
  [88.8406478, 27.7114744],
  [88.8408297, 27.7120027],
  [88.8416947, 27.712331],
  [88.8423848, 27.7125689],
  [88.843051, 27.7127831],
  [88.8432414, 27.712902],
  [88.8438124, 27.7130448],
  [88.8449546, 27.7132352],
  [88.8454543, 27.7133541],
  [88.8457874, 27.7134493],
  [88.8462157, 27.7134017],
  [88.846763, 27.7135207],
  [88.847453, 27.71383],
  [88.848262, 27.714068],
  [88.849071, 27.7144725],
  [88.8496183, 27.714758],
  [88.850356, 27.7149008],
  [88.8509746, 27.7150674],
  [88.8515695, 27.7153529],
  [88.8517122, 27.7152815],
  [88.8522595, 27.7152101],
  [88.8529734, 27.7150911],
  [88.8531399, 27.7149484],
  [88.853473, 27.7149246],
  [88.8536872, 27.7149722],
  [88.8540917, 27.7149722],
  [88.8541869, 27.7150911],
  [88.854401, 27.7155908],
  [88.8545438, 27.7157336],
  [88.8546866, 27.7161619],
  [88.8548769, 27.7164474],
  [88.8551863, 27.7166378],
  [88.8558525, 27.7170185],
  [88.8562094, 27.7171137],
  [88.8567329, 27.7171851],
  [88.8567805, 27.7176372],
  [88.8567329, 27.7181844],
  [88.8565426, 27.7188983],
  [88.8560905, 27.7199215],
  [88.8558763, 27.7204449],
  [88.8558763, 27.7210874],
  [88.8555908, 27.7219678],
  [88.8561856, 27.7223485],
  [88.8565426, 27.7225389],
  [88.8567091, 27.7227054],
  [88.8566865, 27.7228921],
  [88.8566139, 27.7232547],
  [88.8565596, 27.7236173],
  [88.8565414, 27.7240342],
  [88.8565052, 27.7245237],
  [88.8565233, 27.7250494],
  [88.8566321, 27.7253938],
  [88.8569584, 27.7259558],
  [88.8573572, 27.7263546],
  [88.8578467, 27.7267897],
  [88.8587531, 27.7273517],
  [88.8588437, 27.7274605],
  [88.8585174, 27.7290557],
  [88.8584449, 27.7300528],
  [88.8581911, 27.730361],
  [88.8575566, 27.7313762],
  [88.8574297, 27.7317931],
  [88.8575022, 27.7328445],
  [88.8575929, 27.733044],
  [88.8584449, 27.7337691],
  [88.859025, 27.7342948],
  [88.8590975, 27.7345123],
  [88.8589344, 27.7377029],
  [88.8590069, 27.7381017],
  [88.8591156, 27.7383737],
  [88.8608016, 27.7398239],
  [88.8616536, 27.7402046],
  [88.8626688, 27.7405853],
  [88.8637746, 27.7408935],
  [88.864119, 27.7410385],
  [88.8646991, 27.741528],
  [88.8652249, 27.7418724],
  [88.8657324, 27.7420899],
  [88.8664032, 27.7424163],
  [88.8670014, 27.7427063],
  [88.866947, 27.7429238],
  [88.8663488, 27.7440478],
  [88.8659681, 27.7445554],
  [88.8654061, 27.7450992],
  [88.8649711, 27.7458425],
  [88.8647354, 27.7464407],
  [88.8646266, 27.747329],
  [88.8643728, 27.7484348],
  [88.863829, 27.7501389],
  [88.8633214, 27.7515166],
  [88.8628682, 27.7526768],
  [88.8619255, 27.753692],
  [88.861001, 27.754109],
  [88.8599677, 27.7544534],
  [88.8590975, 27.7544715],
  [88.8582636, 27.7542178],
  [88.8573028, 27.7536195],
  [88.8566502, 27.7534564],
  [88.8564689, 27.754109],
  [88.8562514, 27.7552692],
  [88.8559069, 27.7565019],
  [88.8556894, 27.7574265],
  [88.8555806, 27.7588223],
  [88.8555625, 27.759765],
  [88.8554537, 27.7609252],
  [88.8552362, 27.7622304],
  [88.8550549, 27.7628468],
  [88.8544567, 27.7639164],
  [88.8542029, 27.7645871],
  [88.8542391, 27.7652941],
  [88.8543298, 27.7660918],
  [88.8546198, 27.7671069],
  [88.8547649, 27.767687],
  [88.8548374, 27.7682309],
  [88.8548555, 27.7688835],
  [88.8548192, 27.7700075],
  [88.8549461, 27.7706963],
  [88.8552543, 27.7712039],
  [88.8566865, 27.7726179],
  [88.8564689, 27.7735243],
  [88.8562695, 27.774177],
  [88.8562514, 27.7747571],
  [88.8563239, 27.7757541],
  [88.85578, 27.7768056],
  [88.8553087, 27.7774763],
  [88.8552181, 27.778002],
  [88.8553087, 27.7785277],
  [88.8555444, 27.779271],
  [88.8555806, 27.7797423],
  [88.85549, 27.7808844],
  [88.8554537, 27.7817183],
  [88.8554175, 27.7824616],
  [88.8555262, 27.7826791],
  [88.85578, 27.7832955],
  [88.8561789, 27.7841656],
  [88.856487, 27.7849995],
  [88.8569946, 27.7864135],
  [88.8579917, 27.7885527],
  [88.8598045, 27.7919789],
  [88.8602033, 27.7967104],
  [88.8601489, 27.7993934],
  [88.8601308, 27.800336],
  [88.8604753, 27.8010068],
  [88.8608197, 27.8024208],
  [88.8608378, 27.8043242],
  [88.8602033, 27.8042336],
  [88.8601308, 27.8045599],
  [88.8587893, 27.8050131],
  [88.8585899, 27.8065721],
  [88.8594057, 27.8094908],
  [88.8594238, 27.8118293],
  [88.8579554, 27.8141679],
  [88.8570853, 27.8155275],
  [88.85917, 27.8176304],
  [88.8608197, 27.8183374],
  [88.8656599, 27.8217092],
  [88.8662944, 27.8220537],
  [88.8672734, 27.8228332],
  [88.8678172, 27.8234314],
  [88.8725849, 27.8267489],
  [88.8753767, 27.8284167],
  [88.8791836, 27.8293956],
  [88.8804345, 27.8304652],
  [88.8810871, 27.8319517],
  [88.8813046, 27.8324955],
  [88.881776, 27.8333294],
  [88.8825011, 27.8341271],
  [88.8828818, 27.8345984],
  [88.8829362, 27.8349066],
  [88.882773, 27.8353779],
  [88.8826099, 27.8360124],
  [88.8825555, 27.8368282],
  [88.8824286, 27.8374989],
  [88.8821385, 27.8381334],
  [88.8819391, 27.8386591],
  [88.8817216, 27.8390761],
  [88.8815403, 27.8394749],
  [88.8809421, 27.8398556],
  [88.8806701, 27.8402726],
  [88.8805251, 27.8411427],
  [88.8804707, 27.842031],
  [88.8805614, 27.8429737],
  [88.880652, 27.8439163],
  [88.8820116, 27.851639],
  [88.8821023, 27.8517477],
  [88.8832262, 27.8525635],
  [88.884749, 27.8537962],
  [88.8852928, 27.8540138],
  [88.8858548, 27.8543038],
  [88.8861449, 27.8546301],
  [88.8867068, 27.8552102],
  [88.8871963, 27.8556453],
  [88.8875045, 27.8558991],
  [88.8881208, 27.8564067],
  [88.8880302, 27.8568418],
  [88.8878127, 27.8572406],
  [88.8872869, 27.8582558],
  [88.8868519, 27.8590716],
  [88.886453, 27.859706],
  [88.8860723, 27.8604312],
  [88.8856917, 27.8612651],
  [88.8852747, 27.8618452],
  [88.8848034, 27.862244],
  [88.8840782, 27.8627879],
  [88.883335, 27.863368],
  [88.8826642, 27.8639662],
  [88.8818666, 27.864782],
  [88.881359, 27.8653621],
  [88.8809964, 27.8661235],
  [88.8807426, 27.866903],
  [88.880507, 27.8674106],
  [88.8802532, 27.8679907],
  [88.8800175, 27.8688789],
  [88.8797275, 27.8700573],
  [88.8794918, 27.8712175],
  [88.879238, 27.8724683],
  [88.8787485, 27.8739911],
  [88.8786216, 27.8744806],
  [88.8784585, 27.8746437],
  [88.8780597, 27.8749157],
  [88.8780053, 27.8752057],
  [88.8783497, 27.8783781],
  [88.8787304, 27.8798465],
  [88.8786579, 27.8809886],
  [88.8785673, 27.8814962],
  [88.878386, 27.8820401],
  [88.8782047, 27.8825295],
  [88.8782047, 27.8827108],
  [88.8787667, 27.8838529],
  [88.8788392, 27.8839254],
  [88.8798544, 27.8843786],
  [88.8798906, 27.88514],
  [88.8798544, 27.8855569],
  [88.8797637, 27.8858107],
  [88.8796549, 27.8862639],
  [88.8808695, 27.8875692],
  [88.8807608, 27.8885662],
  [88.8813046, 27.889382],
  [88.8814315, 27.891503],
  [88.8813227, 27.8917205],
  [88.8800175, 27.8929895],
  [88.8793286, 27.8932433],
  [88.8791836, 27.8933521],
  [88.8788573, 27.8952193],
  [88.8787667, 27.8953099],
  [88.8759387, 27.8971228],
  [88.8752317, 27.8976122],
  [88.8747059, 27.8982286],
  [88.8721499, 27.900404],
  [88.8719323, 27.900549],
  [88.8708084, 27.9007122],
  [88.8707177, 27.9008209],
  [88.870754, 27.9023981],
  [88.8707902, 27.9034133],
  [88.8706815, 27.9042834],
  [88.8705546, 27.9050086],
  [88.8703008, 27.9055343],
  [88.8700288, 27.9061325],
  [88.8698294, 27.9064951],
  [88.8696663, 27.9071296],
  [88.8696663, 27.907329],
  [88.8698657, 27.908471],
  [88.8698294, 27.908888],
  [88.8696663, 27.9097038],
  [88.8693762, 27.910864],
  [88.8690318, 27.9118973],
  [88.8690318, 27.9121148],
  [88.8693037, 27.9127856],
  [88.8695575, 27.9132932],
  [88.8697388, 27.913982],
  [88.8697569, 27.9141452],
  [88.8694125, 27.9148884],
  [88.8690137, 27.9152329],
  [88.8687236, 27.9154686],
  [88.8687236, 27.9156498],
  [88.8686148, 27.9161756],
  [88.8683248, 27.9167013],
  [88.8682341, 27.9174989],
  [88.8679622, 27.9182422],
  [88.8677266, 27.9186047],
  [88.8676359, 27.9197106],
  [88.8658412, 27.9213965],
  [88.8640284, 27.9232456],
  [88.8614542, 27.9262911],
  [88.8597864, 27.9276145],
  [88.8596414, 27.9277958],
  [88.8583724, 27.9275964],
  [88.8574116, 27.9275782],
  [88.854783, 27.9275238],
  [88.8539853, 27.9273426],
  [88.852245, 27.9269256],
  [88.8508129, 27.9268168],
  [88.8499065, 27.926835],
  [88.8489276, 27.9269619],
  [88.8479486, 27.9271975],
  [88.8466071, 27.9274876],
  [88.8452113, 27.9276507],
  [88.844178, 27.9277414],
  [88.8435435, 27.9277776],
  [88.842909, 27.9279408],
  [88.8418318, 27.9284267],
  [88.8405885, 27.928924],
  [88.8390137, 27.9296285],
  [88.8384336, 27.9297113],
  [88.837729, 27.9294627],
  [88.8366516, 27.9294213],
  [88.8340822, 27.9305816],
  [88.8352011, 27.9329024],
  [88.8354912, 27.9345186],
  [88.8360384, 27.93564],
  [88.8361269, 27.9362595],
  [88.8364367, 27.9388705],
  [88.8367907, 27.9405964],
  [88.837012, 27.9418797],
  [88.8374102, 27.9437384],
  [88.8379855, 27.9454643],
  [88.8381625, 27.9455528],
  [88.8382953, 27.9457741],
  [88.8383396, 27.9460838],
  [88.8398884, 27.9467476],
  [88.840331, 27.9468361],
  [88.8405522, 27.9469689],
  [88.8405965, 27.9472787],
  [88.840685, 27.9474114],
  [88.8417028, 27.947677],
  [88.8419684, 27.9479425],
  [88.8419684, 27.9492258],
  [88.8422339, 27.9498896],
  [88.8430747, 27.951173],
  [88.8430282, 27.9533287],
  [88.8429134, 27.953673],
  [88.8428561, 27.9538452],
  [88.8428561, 27.954046],
  [88.8429421, 27.9541895],
  [88.843143, 27.9543042],
  [88.8433151, 27.9545051],
  [88.8433151, 27.9548494],
  [88.8432577, 27.9554519],
  [88.8432004, 27.9558249],
  [88.8429995, 27.9559396],
  [88.8426839, 27.9561118],
  [88.8425118, 27.9565709],
  [88.8424257, 27.9576898],
  [88.842397, 27.9588949],
  [88.8421962, 27.9596122],
  [88.841651, 27.9607025],
  [88.8418232, 27.9616206],
  [88.8413354, 27.9637725],
  [88.8409337, 27.9657235],
  [88.8401877, 27.9682484],
  [88.8397861, 27.9697691],
  [88.8413641, 27.9730399],
  [88.842024, 27.9748188],
  [88.8420527, 27.97545],
  [88.8420527, 27.9760812],
  [88.8419092, 27.9769707],
  [88.8416223, 27.9780897],
  [88.841278, 27.9794095],
  [88.8410772, 27.9804424],
  [88.8409624, 27.9810162],
  [88.8409624, 27.9817335],
  [88.8409911, 27.9826229],
  [88.8410772, 27.9831394],
  [88.841278, 27.9835124],
  [88.8420814, 27.9842584],
  [88.8423396, 27.9847174],
  [88.8423683, 27.9852913],
  [88.8425118, 27.9863529],
  [88.8418519, 27.9879309],
  [88.8409911, 27.9897672],
  [88.8404173, 27.991087],
  [88.8401017, 27.9923208],
  [88.8397287, 27.9947882],
  [88.839327, 27.9961368],
  [88.8385523, 27.998346],
  [88.8379498, 28.0008422],
  [88.8374907, 28.0031375],
  [88.8370603, 28.0057485],
  [88.836716, 28.0084455],
  [88.8365439, 28.0110278],
  [88.8365439, 28.0120893],
  [88.8366873, 28.0128353],
  [88.8367591, 28.0138299],
  [88.8364582, 28.0146199],
  [88.836082, 28.015297],
  [88.8354425, 28.0158237],
  [88.8343139, 28.0164256],
  [88.8330157, 28.016936],
  [88.8324617, 28.017213],
  [88.8317819, 28.0173893],
  [88.8309258, 28.0174648],
  [88.8299186, 28.0177166],
  [88.8290121, 28.0179432],
  [88.8281308, 28.0182454],
  [88.8274006, 28.0186231],
  [88.8267208, 28.0192526],
  [88.8259654, 28.0197813],
  [88.8252855, 28.0201339],
  [88.8246057, 28.0205367],
  [88.8240769, 28.0208641],
  [88.8234222, 28.0211411],
  [88.8225409, 28.0212166],
  [88.8220625, 28.0213173],
  [88.8214834, 28.0216195],
  [88.8205265, 28.0219468],
  [88.8196956, 28.0220475],
  [88.8192927, 28.021972],
  [88.8183359, 28.0216698],
  [88.8174798, 28.0215439],
  [88.816674, 28.0214432],
  [88.8159438, 28.0215439],
  [88.815264, 28.0217454],
  [88.8144078, 28.022249],
  [88.8133251, 28.0229792],
  [88.8125445, 28.0234828],
  [88.8119654, 28.0239864],
  [88.8112855, 28.0247669],
  [88.8105553, 28.0255979],
  [88.8100266, 28.0263533],
  [88.8097748, 28.0268317],
  [88.8091453, 28.027436],
  [88.8084654, 28.0279648],
  [88.8079366, 28.028418],
  [88.8075086, 28.0288209],
  [88.8067532, 28.0290727],
  [88.8057712, 28.0295259],
  [88.8048647, 28.0298533],
  [88.8042856, 28.0300043],
  [88.8038827, 28.0304072],
  [88.8035805, 28.031213],
  [88.8032784, 28.031918],
  [88.8030014, 28.0327741],
  [88.8025482, 28.0338317],
  [88.8022208, 28.0345115],
  [88.8017424, 28.0349899],
  [88.8013395, 28.0354683],
  [88.8006093, 28.0360475],
  [88.8001309, 28.0365511],
  [88.7996273, 28.037659],
  [88.7992748, 28.0382885],
  [88.7986705, 28.0390187],
  [88.7980158, 28.0397489],
  [88.7975877, 28.0405295],
  [88.7971597, 28.0413101],
  [88.7965554, 28.0420403],
  [88.7960014, 28.0424431],
  [88.7950194, 28.0430978],
  [88.7942892, 28.043677],
  [88.7938863, 28.0442057],
  [88.7934583, 28.0452633],
  [88.793282, 28.0462201],
  [88.7931057, 28.0475546],
  [88.7929043, 28.0481338],
  [88.7925518, 28.0486122],
  [88.7919978, 28.0488892],
  [88.7914942, 28.049141],
  [88.7909403, 28.0495438],
  [88.7901345, 28.0497453],
  [88.7897568, 28.0497453],
  [88.7891273, 28.0496949],
  [88.7886489, 28.0497453],
  [88.7880698, 28.0498964],
  [88.7870878, 28.0504251],
  [88.7862317, 28.0509791],
  [88.7853, 28.0518352],
  [88.7844187, 28.0529179],
  [88.7840158, 28.0532201],
  [88.7833863, 28.0533964],
  [88.782782, 28.0534467],
  [88.7821022, 28.0533712],
  [88.7805159, 28.0532956],
  [88.7794583, 28.0532705],
  [88.7785531, 28.0534075],
  [88.7778738, 28.0538442],
  [88.7771945, 28.0544507],
  [88.7766002, 28.0552271],
  [88.7754842, 28.0564765],
  [88.7742832, 28.0577017],
  [88.7735069, 28.0584295],
  [88.7728519, 28.0590482],
  [88.7725486, 28.059497],
  [88.772306, 28.0601399],
  [88.7719785, 28.0607949],
  [88.7715175, 28.0614742],
  [88.7709595, 28.062105],
  [88.770353, 28.0626509],
  [88.7697222, 28.0631361],
  [88.7695888, 28.0633181],
  [88.7694311, 28.0639003],
  [88.7690672, 28.0645918],
  [88.7685092, 28.0652832],
  [88.7681574, 28.0659018],
  [88.7679269, 28.0665084],
  [88.7676843, 28.066848],
  [88.7672355, 28.0671877],
  [88.7665683, 28.0676607],
  [88.7659739, 28.068061],
  [88.7651248, 28.0684614],
  [88.7645789, 28.0688253],
  [88.7643363, 28.0693469],
  [88.7642271, 28.0699534],
  [88.7639967, 28.070657],
  [88.7633537, 28.0712877],
  [88.7626987, 28.0716759],
  [88.7621909, 28.0720023],
  [88.7616841, 28.0722839],
  [88.7610083, 28.0729033],
  [88.7602362, 28.0735076],
  [88.7589747, 28.0745872],
  [88.7578708, 28.0759822],
  [88.7570459, 28.0770497],
  [88.7567063, 28.0776926],
  [88.7559421, 28.0789178],
  [88.7547412, 28.0803006],
  [88.7525577, 28.0796698],
  [88.7512476, 28.0791482],
  [88.749149, 28.0783355],
  [88.7473785, 28.0776485],
  [88.7466028, 28.0773837],
  [88.7458083, 28.0771188],
  [88.7453542, 28.0770999],
  [88.7446542, 28.0770053],
  [88.7443137, 28.0768918],
  [88.7438975, 28.0764756],
  [88.7434624, 28.0760026],
  [88.7431029, 28.0755486],
  [88.7427056, 28.0751702],
  [88.7423462, 28.074981],
  [88.7417975, 28.0748675],
  [88.7409651, 28.0748486],
  [88.7401516, 28.0749999],
  [88.7394895, 28.0750188],
  [88.7390544, 28.0749432],
  [88.7386192, 28.0745459],
  [88.7381841, 28.0742432],
  [88.7378436, 28.0742053],
  [88.7371058, 28.0741864],
  [88.7366517, 28.0740729],
  [88.7359139, 28.0734864],
  [88.7353463, 28.0732594],
  [88.7341355, 28.0732783],
  [88.732868, 28.0733162],
  [88.7320923, 28.0731081],
  [88.7311464, 28.0728243],
  [88.7304464, 28.0726351],
  [88.7294816, 28.0726729],
  [88.7285357, 28.0726919],
  [88.728006, 28.0729756],
  [88.7276465, 28.0733918],
  [88.7273249, 28.0737513],
  [88.7271653, 28.0739535],
  [88.7270411, 28.0741108],
  [88.7268519, 28.0742621],
  [88.7257925, 28.0743189],
  [88.7248844, 28.0742999],
  [88.724052, 28.0745459],
  [88.7230871, 28.0750567],
  [88.7218007, 28.075454],
  [88.7204196, 28.0756432],
  [88.7195494, 28.0757567],
  [88.7192888, 28.0758435],
  [88.7190953, 28.075908],
  [88.7184899, 28.0763431],
  [88.7173927, 28.0771756],
  [88.7164278, 28.0776296],
  [88.7155954, 28.0778377],
  [88.7143657, 28.078008],
  [88.7134198, 28.0780458],
  [88.7124739, 28.0780836],
  [88.7115279, 28.0780836],
  [88.7104874, 28.0781215],
  [88.7094469, 28.0780269],
  [88.7088037, 28.0779701],
  [88.7082739, 28.0778188],
  [88.7075361, 28.0776674],
  [88.7068172, 28.0775728],
  [88.7060794, 28.0774026],
  [88.705474, 28.0772891],
  [88.7049443, 28.0772891],
  [88.7038281, 28.0775539],
  [88.7028633, 28.0776296],
  [88.7018038, 28.0775918],
  [88.7003849, 28.0772134],
  [88.6987001, 28.0768381],
  [88.6968001, 28.0770417],
  [88.6957499, 28.077762],
  [88.6944067, 28.0785566],
  [88.6927986, 28.0783296],
  [88.6913986, 28.0786134],
  [88.6896203, 28.0792566],
  [88.6879366, 28.0791998],
  [88.6855907, 28.0789161],
  [88.6842853, 28.0784053],
  [88.6834718, 28.0776107],
  [88.6819583, 28.077062],
  [88.6808793, 28.0762437],
  [88.6795105, 28.0718712],
  [88.6791303, 28.0718332],
  [88.6771532, 28.0725176],
  [88.6765829, 28.0727077],
  [88.6736552, 28.073316],
  [88.6706135, 28.0741145],
  [88.6686364, 28.0752171],
  [88.6671803, 28.0764873],
  [88.6670795, 28.0766291],
  [88.6668494, 28.0788672],
  [88.6663551, 28.0805781],
  [88.6655947, 28.0817948],
  [88.6652905, 28.0826693],
  [88.6654806, 28.08381],
  [88.6653665, 28.0849886],
  [88.6648343, 28.087422],
  [88.6646822, 28.0877262],
  [88.664416, 28.0880303],
  [88.6638077, 28.0887147],
  [88.6622868, 28.0897793],
  [88.6607279, 28.0904257],
  [88.659055, 28.0907679],
  [88.6582565, 28.0915663],
  [88.6571539, 28.0935815],
  [88.6557091, 28.0949122],
  [88.6540362, 28.0954065],
  [88.6522111, 28.096319],
  [88.6512165, 28.0966173],
  [88.6503101, 28.0973836],
  [88.6495496, 28.0983722],
  [88.6485611, 28.0987904],
  [88.6469649, 28.0997919],
  [88.6457828, 28.1009138],
  [88.6443787, 28.103467],
  [88.6436943, 28.1057103],
  [88.6432001, 28.1084098],
  [88.6422495, 28.1100067],
  [88.641917, 28.1103648],
  [88.6418541, 28.1104186],
  [88.641337, 28.1109572],
  [88.6408427, 28.1121359],
  [88.6405386, 28.1135047],
  [88.6403104, 28.1151396],
  [88.6398162, 28.1161662],
  [88.6390557, 28.1168886],
  [88.6379531, 28.1173829],
  [88.6374208, 28.1179152],
  [88.6360901, 28.1202345],
  [88.6347593, 28.1214892],
  [88.6333677, 28.1226411],
  [88.6270465, 28.1158569],
  [88.6266013, 28.1156076],
  [88.6223991, 28.1140407],
  [88.6186763, 28.1130104],
  [88.6180679, 28.1128583],
  [88.6172695, 28.1128583],
  [88.6156033, 28.1124432],
  [88.614608, 28.1120218],
  [88.6124788, 28.1107291],
  [88.6108439, 28.1093223],
  [88.6089428, 28.1070791],
  [88.6076881, 28.1044176],
  [88.6064334, 28.1020222],
  [88.6056246, 28.1001662],
  [88.6048597, 28.0986044],
  [88.6041425, 28.0971541],
  [88.6038078, 28.0961661],
  [88.603505, 28.0950983],
  [88.6028974, 28.0934674],
  [88.6025172, 28.0922887],
  [88.6023651, 28.091034],
  [88.6023651, 28.0896272],
  [88.6006541, 28.089247],
  [88.5988671, 28.0890189],
  [88.5973082, 28.089209],
  [88.5957113, 28.0896653],
  [88.5943426, 28.0896653],
  [88.5924795, 28.0890569],
  [88.5913008, 28.0889048],
  [88.5890196, 28.0884486],
  [88.5874107, 28.086116],
  [88.5872326, 28.0858631],
  [88.5866242, 28.0847985],
  [88.5856357, 28.0847605],
  [88.5832783, 28.0841521],
  [88.5820616, 28.0842662],
  [88.5806168, 28.0846844],
  [88.579096, 28.0849126],
  [88.576834, 28.0851398],
  [88.5754122, 28.0859434],
  [88.5739287, 28.0879215],
  [88.5717449, 28.089899],
  [88.5711215, 28.0904185],
  [88.5701345, 28.0907561],
  [88.5691474, 28.0909639],
  [88.5681863, 28.090912],
  [88.567459, 28.0906782],
  [88.5662901, 28.0903146],
  [88.565381, 28.0901327],
  [88.5644719, 28.0900288],
  [88.5635108, 28.0882366],
  [88.5626796, 28.0868599],
  [88.5621082, 28.0862625],
  [88.5607315, 28.0850936],
  [88.5600302, 28.0844962],
  [88.5590951, 28.0841325],
  [88.5579522, 28.0837948],
  [88.5567313, 28.0834052],
  [88.5555625, 28.0828597],
  [88.5544455, 28.0822883],
  [88.5544196, 28.0820285],
  [88.5546274, 28.0814051],
  [88.554965, 28.0804441],
  [88.5553806, 28.0791453],
  [88.5559001, 28.0777167],
  [88.5564196, 28.0766777],
  [88.5573028, 28.0755088],
  [88.5573028, 28.075301],
  [88.5569391, 28.0739503],
  [88.5564716, 28.0726516],
  [88.5562118, 28.0716905],
  [88.5556923, 28.0702619],
  [88.5553547, 28.069093],
  [88.5550949, 28.06808],
  [88.554991, 28.0669371],
  [88.5549131, 28.0652227],
  [88.5547832, 28.063872],
  [88.5542118, 28.0614823],
  [88.5538741, 28.0596121],
  [88.5537962, 28.0585211],
  [88.5538221, 28.0575601],
  [88.553978, 28.0567808],
  [88.5541338, 28.055508],
  [88.5534325, 28.0549885],
  [88.5526792, 28.0540534],
  [88.5514584, 28.0517936],
  [88.550887, 28.0505208],
  [88.5504714, 28.0495078],
  [88.5502895, 28.0485727],
  [88.5502376, 28.0476116],
  [88.5502636, 28.0468064],
  [88.5503415, 28.0458713],
  [88.5506532, 28.0448063],
  [88.5509909, 28.0438193],
  [88.5513285, 28.0429621],
  [88.5517441, 28.0422348],
  [88.5526533, 28.0414036],
  [88.5528091, 28.0405724],
  [88.5533546, 28.0401308],
  [88.5535104, 28.0397932],
  [88.5535624, 28.0394295],
  [88.5533286, 28.0394035],
  [88.5531208, 28.0392217],
  [88.5520299, 28.038884],
  [88.5517182, 28.0386503],
  [88.5505493, 28.037923],
  [88.5495882, 28.0372736],
  [88.5489648, 28.0367801],
  [88.5484973, 28.0361826],
  [88.5480297, 28.035767],
  [88.5468868, 28.0350397],
  [88.5459517, 28.0340007],
  [88.5447828, 28.0341046],
  [88.5387047, 28.0335592],
  [88.5362371, 28.0341566],
  [88.5343928, 28.0340007],
  [88.5327304, 28.0349099],
  [88.5320031, 28.0355852],
  [88.5313278, 28.0363645],
  [88.5304966, 28.0372476],
  [88.5296654, 28.0377152],
  [88.5288861, 28.0378191],
  [88.5280549, 28.0376892],
  [88.5273796, 28.0373255],
  [88.5267042, 28.036884],
  [88.5257431, 28.0360787],
  [88.5251977, 28.0355592],
  [88.5246262, 28.0353255],
  [88.5231716, 28.0363904],
  [88.5231976, 28.0377671],
  [88.5229898, 28.0389879],
  [88.5227041, 28.0397932],
  [88.521717, 28.0408062],
  [88.5207559, 28.0417153],
  [88.519613, 28.043118],
  [88.5185221, 28.043092],
  [88.5176649, 28.0429881],
  [88.5164441, 28.0429361],
  [88.5151973, 28.0428582],
  [88.5135868, 28.0425465],
  [88.5121582, 28.0423647],
  [88.5110153, 28.0421829],
  [88.5100542, 28.0429621],
  [88.5091451, 28.0434816],
  [88.5083399, 28.0439751],
  [88.5077425, 28.0443128],
  [88.5072489, 28.0453258],
  [88.5066775, 28.0463648],
  [88.5057164, 28.047248],
  [88.5049891, 28.0480013],
  [88.5045995, 28.0487286],
  [88.5037683, 28.0498195],
  [88.503067, 28.0505208],
  [88.5022098, 28.0511183],
  [88.5013266, 28.0518975],
  [88.5004695, 28.0523391],
  [88.4994305, 28.0531963],
  [88.4984954, 28.0538197],
  [88.4973005, 28.0543651],
  [88.4967031, 28.0546768],
  [88.4949108, 28.0551184],
  [88.4933263, 28.0505208],
  [88.4922354, 28.048261],
  [88.4911964, 28.0470662],
  [88.4901314, 28.0463332],
  [88.489482, 28.046157],
  [88.487378, 28.0456895],
  [88.485326, 28.0450921],
  [88.4839753, 28.0442609],
  [88.4820532, 28.042053],
  [88.4813059, 28.041298],
  [88.4802019, 28.0406847],
  [88.4798443, 28.040486],
  [88.4774084, 28.0384155],
  [88.4754597, 28.0362638],
  [88.4748508, 28.0347617],
  [88.4743957, 28.0330055],
  [88.4744713, 28.0319464],
  [88.474547, 28.0313033],
  [88.4745091, 28.030509],
  [88.4743578, 28.0297903],
  [88.4739796, 28.0289203],
  [88.4737526, 28.0279746],
  [88.4732231, 28.0269533],
  [88.4728826, 28.0260455],
  [88.4727313, 28.0249107],
  [88.4724665, 28.0246081],
  [88.4720883, 28.0241542],
  [88.4715965, 28.0233598],
  [88.4713317, 28.0228302],
  [88.4711426, 28.0220359],
  [88.4709913, 28.0218089],
  [88.47084, 28.0216955],
  [88.4703483, 28.0210902],
  [88.4700457, 28.0205229],
  [88.4695539, 28.0197285],
  [88.468873, 28.018745],
  [88.4685326, 28.0181398],
  [88.4681543, 28.0168915],
  [88.4669061, 28.0169672],
  [88.4655443, 28.0169672],
  [88.4642583, 28.0169294],
  [88.4632369, 28.0167402],
  [88.4625183, 28.0163241],
  [88.4617239, 28.0157189],
  [88.4610052, 28.0149246],
  [88.4603622, 28.0153407],
  [88.4598704, 28.0155676],
  [88.4590382, 28.0157189],
  [88.4576387, 28.0158324],
  [88.4566174, 28.0158702],
  [88.4558987, 28.0157189],
  [88.4550665, 28.0150381],
  [88.4546504, 28.0145085],
  [88.4543478, 28.0143194],
  [88.45344, 28.0141302],
  [88.4522295, 28.0136385],
  [88.4509434, 28.0131468],
  [88.4496952, 28.0125794],
  [88.4480687, 28.0120498],
  [88.4465934, 28.0114824],
  [88.4457991, 28.0110663],
  [88.4439078, 28.0090237],
  [88.4433404, 28.0090615],
  [88.4422813, 28.009175],
  [88.4412599, 28.0091372],
  [88.4403521, 28.0088724],
  [88.4391417, 28.008532],
  [88.4383095, 28.0083807],
  [88.4372882, 28.0081915],
  [88.4366452, 28.0078133],
  [88.4353969, 28.0069811],
  [88.434073, 28.0071324],
  [88.4334299, 28.0073215],
  [88.4326356, 28.0076998],
  [88.4313117, 28.0075107],
  [88.4289286, 28.0067163],
  [88.4280964, 28.005468],
  [88.4273399, 28.0046737],
  [88.4265077, 28.0039172],
  [88.4254108, 28.003085],
  [88.4248434, 28.0025933],
  [88.4241625, 28.0018367],
  [88.423633, 28.0013828],
  [88.4230656, 28.0011937],
  [88.4217795, 28.0010046],
  [88.4209473, 28.0008154],
  [88.4195477, 28.0006263],
  [88.4181482, 28.0003993],
  [88.4173538, 27.9999832],
  [88.4167486, 27.999378],
  [88.4162947, 27.9985837],
  [88.4159164, 27.9981298],
  [88.4152734, 27.9974489],
  [88.4149708, 27.9966167],
  [88.414706, 27.9957089],
  [88.4142142, 27.9945741],
  [88.4141386, 27.9937041],
  [88.4141764, 27.9927585],
  [88.4138738, 27.9913589],
  [88.4135712, 27.9901863],
  [88.4130416, 27.9875763],
  [88.4128525, 27.9861389],
  [88.4129282, 27.9849663],
  [88.4137331, 27.9817981],
  [88.413643, 27.9814633],
  [88.4135914, 27.981077],
  [88.4118787, 27.9812701],
  [88.4107712, 27.9813989],
  [88.4097281, 27.9811156],
  [88.4094706, 27.9806263],
  [88.4093804, 27.9802399],
  [88.4079639, 27.9804331],
  [88.406895, 27.9803945],
  [88.4062898, 27.9804331],
  [88.4055686, 27.9805619],
  [88.4051436, 27.9807937],
  [88.4047573, 27.9809611],
  [88.4046157, 27.9808581],
  [88.4044483, 27.9808194],
  [88.4027613, 27.9807164],
  [88.4025552, 27.9806005],
  [88.402259, 27.9803687],
  [88.4018212, 27.9799309],
  [88.401422, 27.9792355],
  [88.4009584, 27.9782439],
  [88.4004561, 27.9772394],
  [88.4001342, 27.9773038],
  [88.3994259, 27.9776],
  [88.3987305, 27.9779477],
  [88.3981639, 27.9783211],
  [88.3973269, 27.9786173],
  [88.3968633, 27.978656],
  [88.3963353, 27.9784757],
  [88.3953823, 27.9781409],
  [88.3941976, 27.9777803],
  [88.3934378, 27.9774197],
  [88.392884, 27.9770462],
  [88.3919826, 27.9766599],
  [88.3910039, 27.9765054],
  [88.3898449, 27.9765054],
  [88.3892911, 27.976647],
  [88.3887889, 27.977175],
  [88.3883253, 27.9777932],
  [88.3881837, 27.9783083],
  [88.3880162, 27.9792226],
  [88.3877458, 27.9798665],
  [88.3873724, 27.9804073],
  [88.3869474, 27.9807164],
  [88.3862262, 27.9810255],
  [88.385724, 27.9814762],
  [88.3850801, 27.9820686],
  [88.3845393, 27.9824034],
  [88.3840628, 27.9824678],
  [88.3834575, 27.9824935],
  [88.3827235, 27.9825579],
  [88.3817705, 27.9827382],
  [88.3810236, 27.9829314],
  [88.3806373, 27.9831761],
  [88.3803025, 27.9836397],
  [88.3798002, 27.9845926],
  [88.3794268, 27.9852751],
  [88.3789632, 27.9862667],
  [88.3786026, 27.9869621],
  [88.378139, 27.9880181],
  [88.3776883, 27.9889968],
  [88.3773277, 27.9892672],
  [88.376877, 27.9893187],
  [88.3759884, 27.9893187],
  [88.3752029, 27.9894218],
  [88.3740954, 27.9896536],
  [88.373057, 27.9897521],
  [88.3725887, 27.9896664],
  [88.3722925, 27.9893574],
  [88.372138, 27.9890612],
  [88.3716486, 27.9889582],
  [88.3713138, 27.9888294],
  [88.3706699, 27.9885976],
  [88.3700776, 27.988559],
  [88.3694852, 27.9886233],
  [88.3689443, 27.9885847],
  [88.3684034, 27.988559],
  [88.3679085, 27.9886148],
  [88.367615, 27.9888716],
  [88.3670402, 27.9893608],
  [88.3667222, 27.9893975],
  [88.3662697, 27.9895075],
  [88.3649947, 27.9890512],
  [88.3642621, 27.9891409],
  [88.3637389, 27.9891858],
  [88.3632006, 27.9891559],
  [88.3624531, 27.9890363],
  [88.3615411, 27.9888718],
  [88.3607337, 27.9888269],
  [88.3598366, 27.9888718],
  [88.3591937, 27.9891409],
  [88.3583266, 27.9892755],
  [88.3571521, 27.9894257],
  [88.3570408, 27.9894399],
  [88.3559045, 27.9893652],
  [88.3546636, 27.9890064],
  [88.3527199, 27.9885578],
  [88.3516136, 27.9882289],
  [88.3506118, 27.9877056],
  [88.3498643, 27.9870029],
  [88.3489373, 27.9862255],
  [88.3480104, 27.985747],
  [88.3456331, 27.9855676],
  [88.342329, 27.9855826],
  [88.3412375, 27.9856723],
  [88.3404003, 27.9858068],
  [88.3402059, 27.9856125],
  [88.3398321, 27.9852536],
  [88.339563, 27.9851041],
  [88.3385164, 27.9848799],
  [88.3369017, 27.9845509],
  [88.3358253, 27.9843117],
  [88.3348665, 27.9842081],
  [88.3336382, 27.9819476],
  [88.3330633, 27.9803535],
  [88.3323577, 27.9779754],
  [88.3318612, 27.975937],
  [88.3314457, 27.9759455],
  [88.3304523, 27.9760984],
  [88.3297428, 27.9764477],
  [88.3290441, 27.9770044],
  [88.3281272, 27.977583],
  [88.3271665, 27.9779869],
  [88.3263078, 27.9780911],
  [88.3252221, 27.9781961],
  [88.3242064, 27.9786865],
  [88.3234359, 27.9792118],
  [88.3225424, 27.9798201],
  [88.3218642, 27.9799951],
  [88.3211642, 27.9798092],
  [88.3205079, 27.9797436],
  [88.3199062, 27.9796451],
  [88.3191843, 27.979306],
  [88.3184952, 27.9789669],
  [88.3178498, 27.9784966],
  [88.3172591, 27.9781903],
  [88.3159575, 27.9778293],
  [88.3154215, 27.9775121],
  [88.3151371, 27.9769214],
  [88.3151809, 27.9754885],
  [88.3148089, 27.974701],
  [88.3147543, 27.9738259],
  [88.31481, 27.9729356],
  [88.3142496, 27.9726554],
  [88.313339, 27.972025],
  [88.3123934, 27.9713946],
  [88.3115178, 27.970554],
  [88.3109227, 27.9699873],
  [88.3107132, 27.9696422],
  [88.3104913, 27.969371],
  [88.3099737, 27.968952],
  [88.3094068, 27.9686562],
  [88.3088892, 27.9684097],
  [88.3084208, 27.9682988],
  [88.3079895, 27.968114],
  [88.307669, 27.9678428],
  [88.3070651, 27.9676826],
  [88.3066954, 27.9679537],
  [88.3065475, 27.9679414],
  [88.3062394, 27.9677812],
  [88.3060792, 27.9676703],
  [88.3059929, 27.9675347],
  [88.3059066, 27.9672759],
  [88.3055492, 27.9673252],
  [88.3052288, 27.9673622],
  [88.3050439, 27.9672882],
  [88.3048467, 27.9672636],
  [88.3047482, 27.9672266],
  [88.3046619, 27.967091],
  [88.3045633, 27.9660804],
  [88.3044647, 27.9657107],
  [88.3041689, 27.9654519],
  [88.3035034, 27.9652177],
  [88.3028748, 27.9651807],
  [88.3016054, 27.9656984],
  [88.3010385, 27.9657723],
  [88.3007427, 27.9658093],
  [88.2999786, 27.9659695],
  [88.299424, 27.9661174],
  [88.2986845, 27.9664009],
  [88.2979081, 27.966635],
  [88.2974644, 27.9667213],
  [88.2972303, 27.9667213],
  [88.2964045, 27.9666966],
  [88.2957267, 27.966709],
  [88.2952707, 27.9668322],
  [88.2949133, 27.9670417],
  [88.2945435, 27.9674115],
  [88.2943464, 27.9677196],
  [88.2940875, 27.9677072],
  [88.2929167, 27.9671403],
  [88.291105, 27.9660804],
  [88.2908131, 27.9657379],
  [88.2905126, 27.9647815],
  [88.2886271, 27.9647542],
  [88.2884358, 27.9648089],
  [88.2881626, 27.965492],
  [88.2880532, 27.9657106],
  [88.2875067, 27.9657926],
  [88.2864137, 27.9657926],
  [88.2852934, 27.9655466],
  [88.284173, 27.9651641],
  [88.2835445, 27.9644809],
  [88.2831619, 27.9633606],
  [88.282998, 27.9627048],
  [88.2826974, 27.9620216],
  [88.2822329, 27.96071],
  [88.2820962, 27.9602181],
  [88.2820962, 27.9584693],
  [88.2819323, 27.9580047],
  [88.2815224, 27.9572123],
  [88.2811945, 27.9567751],
  [88.2809212, 27.9566385],
  [88.2795276, 27.9550262],
  [88.2792544, 27.9547803],
  [88.2786805, 27.954753],
  [88.2778061, 27.9548076],
  [88.276877, 27.9548623],
  [88.2735979, 27.9545617],
  [88.2721224, 27.9543704],
  [88.2714392, 27.9544251],
  [88.2706468, 27.9548623],
  [88.2695264, 27.9558733],
  [88.2680781, 27.9570483],
  [88.2647171, 27.9565292],
  [88.2638973, 27.9561193],
  [88.2634874, 27.9557367],
  [88.2632961, 27.9551082],
  [88.2632688, 27.9540152],
  [88.2629136, 27.9508181],
  [88.2602903, 27.9458448],
  [88.2592519, 27.9457628],
  [88.2582955, 27.9457082],
  [88.2573665, 27.9456535],
  [88.2566013, 27.9454622],
  [88.255481, 27.9452163],
  [88.2547978, 27.9449431],
  [88.254224, 27.9445878],
  [88.2536228, 27.9437954],
  [88.2522839, 27.9419099],
  [88.2520653, 27.9418826],
  [88.2517647, 27.9418826],
  [88.2515461, 27.9417459],
  [88.2513275, 27.941664],
  [88.2512728, 27.9425384],
  [88.2511635, 27.9428936],
  [88.2509722, 27.9431122],
  [88.2504257, 27.9433855],
  [88.2499339, 27.9436041],
  [88.249524, 27.943932],
  [88.2485949, 27.9445878],
  [88.2483763, 27.9449704],
  [88.248349, 27.9463093],
  [88.2481304, 27.9475117],
  [88.2474746, 27.9485774],
  [88.2467751, 27.9495721],
  [88.2463944, 27.9503811],
  [88.2458233, 27.951904],
  [88.2455378, 27.9525702],
  [88.2450143, 27.9529034],
  [88.2444432, 27.9535696],
  [88.2439674, 27.9542359],
  [88.243539, 27.9552828],
  [88.2431583, 27.9561394],
  [88.2429204, 27.9563298],
  [88.2428728, 27.9568533],
  [88.2427776, 27.9573292],
  [88.2423493, 27.9579002],
  [88.2413499, 27.9609459],
  [88.2398747, 27.9632302],
  [88.2393036, 27.9643724],
  [88.2390181, 27.9661332],
  [88.2386374, 27.967656],
  [88.2382566, 27.9680843],
  [88.237638, 27.969512],
  [88.2369717, 27.9694644],
  [88.2356392, 27.9690837],
  [88.234973, 27.9687506],
  [88.2335453, 27.9684174],
  [88.2320225, 27.9680367],
  [88.2306424, 27.9672753],
  [88.2300947, 27.9669723],
  [88.2284057, 27.966038],
  [88.2272159, 27.9650862],
  [88.2260738, 27.964182],
  [88.2250268, 27.9630399],
  [88.2244082, 27.9621833],
  [88.2239323, 27.9612791],
  [88.223504, 27.9609459],
  [88.2229805, 27.9612315],
  [88.2224094, 27.9618025],
  [88.2218384, 27.9619453],
  [88.2210769, 27.9619929],
  [88.2204583, 27.9621833],
  [88.22003, 27.9621833],
  [88.2189354, 27.9623736],
  [88.2183168, 27.9628495],
  [88.2176981, 27.9629923],
  [88.2170794, 27.9629923],
  [88.2161277, 27.9630874],
  [88.2156518, 27.9632778],
  [88.2148903, 27.9635633],
  [88.2135578, 27.9638489],
  [88.2129868, 27.9640392],
  [88.212844, 27.9638013],
  [88.2125585, 27.9635158],
  [88.2116543, 27.9636109],
  [88.2107977, 27.9634682],
  [88.2096555, 27.9629447],
  [88.2088465, 27.9623736],
  [88.208561, 27.9619453],
  [88.2084182, 27.9613266],
  [88.2082278, 27.9609459],
  [88.2078947, 27.9609459],
  [88.2073712, 27.9608983],
  [88.2070381, 27.9606604],
  [88.2066098, 27.9600417],
  [88.2060387, 27.9585189],
  [88.2058137, 27.9579638],
  [88.2053249, 27.9567581],
  [88.2053725, 27.9559491],
  [88.2054201, 27.9545214],
  [88.2055153, 27.9529985],
  [88.2059436, 27.9517136],
  [88.2057056, 27.9462885],
  [88.2059912, 27.9459553],
  [88.2070857, 27.9422434],
  [88.2069905, 27.9406729],
  [88.2037545, 27.9408633],
  [88.2023268, 27.9411488],
  [88.2009467, 27.9414819],
  [88.1996142, 27.9416723],
  [88.1992811, 27.9420054],
  [88.1989004, 27.9423861],
  [88.1985196, 27.9426241],
  [88.1981865, 27.9427669],
  [88.1973775, 27.9436235],
  [88.196045, 27.9435283],
  [88.1954263, 27.9435759],
  [88.1946173, 27.9434807],
  [88.1941414, 27.9431952],
  [88.1936655, 27.9433379],
  [88.1931421, 27.9433379],
  [88.191762, 27.9431952],
  [88.1898584, 27.942862],
  [88.1874314, 27.9426717],
  [88.1858609, 27.9433855],
  [88.185385, 27.9438138],
  [88.1848615, 27.9439566],
  [88.1841001, 27.9442897],
  [88.1833387, 27.944718],
  [88.1828152, 27.9449084],
  [88.1824821, 27.9452415],
  [88.1821014, 27.945765],
  [88.1817207, 27.9462409],
  [88.1813875, 27.946574],
  [88.181102, 27.9470499],
  [88.180864, 27.9476685],
  [88.1805309, 27.9482396],
  [88.1801026, 27.9486679],
  [88.1796267, 27.949001],
  [88.1789605, 27.949239],
  [88.178437, 27.9493818],
  [88.177628, 27.9495721],
  [88.1767714, 27.9495245],
  [88.1750582, 27.9493818],
  [88.1727739, 27.9490486],
  [88.1723932, 27.9505239],
  [88.1718221, 27.9514757],
  [88.171251, 27.9521895],
  [88.1699185, 27.9532841],
  [88.1692523, 27.95376],
  [88.1683005, 27.9544262],
  [88.1673011, 27.954569],
  [88.1661114, 27.9552352],
  [88.1649692, 27.9559015],
  [88.1643506, 27.9566629],
  [88.163494, 27.9575671],
  [88.1631133, 27.9586141],
  [88.1625898, 27.9587092],
  [88.1612573, 27.9587568],
  [88.1594965, 27.9585189],
  [88.1583068, 27.9586141],
  [88.156546, 27.9583285],
  [88.155261, 27.9583285],
  [88.1535478, 27.9586141],
  [88.1527388, 27.95909],
  [88.1518346, 27.9601369],
  [88.1504545, 27.9616122],
  [88.1483606, 27.9630399],
  [88.1465046, 27.9639441],
  [88.1453149, 27.9645151],
  [88.1441252, 27.9648482],
  [88.1424595, 27.9652766],
  [88.141365, 27.9636109],
  [88.1401753, 27.9617549],
  [88.1389855, 27.9596134],
  [88.1382241, 27.9582809],
  [88.1373675, 27.9569008],
  [88.136844, 27.9557111],
  [88.1359874, 27.9549497],
  [88.1353212, 27.9541883],
  [88.1350832, 27.9532365],
  [88.1342742, 27.951904],
  [88.1332272, 27.9514757],
  [88.1319899, 27.950857],
  [88.1308002, 27.9506191],
  [88.1300864, 27.9501908],
  [88.1308478, 27.9495721],
  [88.1317044, 27.9486679],
  [88.1330845, 27.9475258],
  [88.1340838, 27.946574],
  [88.1347977, 27.9453843],
  [88.1354639, 27.9434331],
  [88.1361778, 27.9416247],
  [88.1365585, 27.9393404],
  [88.1367488, 27.9373417],
  [88.1361778, 27.9371037],
  [88.1357019, 27.9367706],
  [88.1350832, 27.9365803],
  [88.1339887, 27.9360092],
  [88.132561, 27.9352002],
  [88.1312761, 27.9328207],
  [88.1301815, 27.9308695],
  [88.1299912, 27.9297274],
  [88.1300864, 27.9283473],
  [88.1303243, 27.9269672],
  [88.1297532, 27.9262058],
  [88.1285635, 27.9256823],
  [88.1277545, 27.9255871],
  [88.1268979, 27.9258727],
  [88.1259461, 27.9256823],
  [88.1243756, 27.9251113],
  [88.1230907, 27.9245878],
  [88.1217582, 27.9237312],
  [88.1207113, 27.9226842],
  [88.120283, 27.9218276],
  [88.1195215, 27.9203047],
  [88.118427, 27.9189722],
  [88.1179987, 27.918068],
  [88.1181415, 27.9169259],
  [88.1187601, 27.9160217],
  [88.1201402, 27.9136422],
  [88.1210444, 27.9118339],
  [88.1213299, 27.9109773],
  [88.1214251, 27.9096923],
  [88.1213775, 27.9088357],
  [88.1215679, 27.9084074],
  [88.12202, 27.9080886],
  [88.1228766, 27.907232],
  [88.1239045, 27.9057472],
  [88.1247611, 27.9036913],
  [88.125789, 27.9034058],
  [88.126817, 27.9031203],
  [88.1275594, 27.9026634],
  [88.1280733, 27.9017497],
  [88.1291013, 27.899751],
  [88.1297865, 27.899237],
  [88.1306431, 27.898723],
  [88.1312713, 27.8982091],
  [88.1315569, 27.8975809],
  [88.1318861, 27.8971811],
  [88.132223, 27.8967649],
  [88.1326126, 27.896266],
  [88.1334484, 27.8952939],
  [88.1345408, 27.893699],
  [88.1356414, 27.8926305],
  [88.1372547, 27.8910162],
  [88.137892, 27.8899691],
  [88.1378514, 27.8886887],
  [88.1368369, 27.8863831],
  [88.1361863, 27.8851762],
  [88.1353595, 27.8831129],
  [88.1349737, 27.8815971],
  [88.1351808, 27.8815306],
  [88.1353629, 27.881452],
  [88.1355421, 27.881395],
  [88.1361985, 27.8808264],
  [88.1376616, 27.8795661],
  [88.1385911, 27.878983],
  [88.1387959, 27.8782368],
  [88.1388726, 27.8781053],
  [88.1389987, 27.8779644],
  [88.1391965, 27.8778748],
  [88.1392738, 27.8778629],
  [88.1396319, 27.878187],
  [88.1398459, 27.8783116],
  [88.140059, 27.8783595],
  [88.1411167, 27.8785086],
  [88.1418865, 27.8785587],
  [88.1428149, 27.878551],
  [88.1439896, 27.8783517],
  [88.1456433, 27.8775394],
  [88.1461737, 27.8774019],
  [88.1463919, 27.877267],
  [88.1469573, 27.8771427],
  [88.1473346, 27.8769253],
  [88.1474759, 27.8768993],
  [88.1481227, 27.877027],
  [88.1486251, 27.8769461],
  [88.149637, 27.8761255],
  [88.1499997, 27.8759149],
  [88.1503163, 27.8756596],
  [88.15056, 27.8752357],
  [88.1509375, 27.8747476],
  [88.1517265, 27.8739785],
  [88.1523801, 27.8734248],
  [88.1526038, 27.8731522],
  [88.1529805, 27.8722309],
  [88.1533724, 27.8717742],
  [88.1535993, 27.871091],
  [88.1537676, 27.8709249],
  [88.1541357, 27.8708609],
  [88.1553004, 27.8711151],
  [88.1558925, 27.8711102],
  [88.1570248, 27.870936],
  [88.1581843, 27.8706442],
  [88.1589567, 27.8702903],
  [88.159897, 27.8700817],
  [88.1612744, 27.8696279],
  [88.1624234, 27.869361],
  [88.1627199, 27.8692503],
  [88.1630782, 27.8689901],
  [88.1640627, 27.8685915],
  [88.1646333, 27.8682392],
  [88.1650272, 27.8679269],
  [88.165405, 27.8674702],
  [88.1659942, 27.8669058],
  [88.1661377, 27.8667286],
  [88.1662554, 27.8664614],
  [88.1663672, 27.8663317],
  [88.166654, 27.8659933],
  [88.1669711, 27.8657944],
  [88.167725, 27.8653842],
  [88.1690407, 27.8648],
  [88.169344, 27.8646779],
  [88.1698884, 27.8645537],
  [88.1702555, 27.8643994],
  [88.1706284, 27.8641459],
  [88.170817, 27.8639164],
  [88.1709654, 27.863852],
  [88.171276, 27.8637614],
  [88.1721003, 27.8636348],
  [88.1723963, 27.8634879],
  [88.1732798, 27.8625532],
  [88.1736739, 27.8623175],
  [88.1738001, 27.8621968],
  [88.1739185, 27.8619951],
  [88.1741661, 27.8609462],
  [88.1742565, 27.8607943],
  [88.1754857, 27.8600912],
  [88.1757941, 27.8597976],
  [88.1760102, 27.8594122],
  [88.1760906, 27.8589829],
  [88.1761804, 27.8587814],
  [88.1765107, 27.8582236],
  [88.1768266, 27.8579186],
  [88.1770868, 27.8577472],
  [88.1774282, 27.8576787],
  [88.1776214, 27.85747],
  [88.1777823, 27.8569483],
  [88.1778517, 27.8562819],
  [88.1785226, 27.8566401],
  [88.1794238, 27.8567823],
  [88.1800246, 27.8568393],
  [88.1814814, 27.8571231],
  [88.1820784, 27.8572827],
  [88.182511, 27.8573286],
  [88.1831203, 27.8572737],
  [88.183741, 27.8569909],
  [88.1843535, 27.8569473],
  [88.185105, 27.8569657],
  [88.185239, 27.8569217],
  [88.1854285, 27.8567756],
  [88.1861263, 27.855768],
  [88.1871809, 27.8546558],
  [88.1875254, 27.854425],
  [88.1882442, 27.8540556],
  [88.1884859, 27.8541144],
  [88.1888636, 27.8543007],
  [88.1897396, 27.8543879],
  [88.1910272, 27.8547986],
  [88.1919134, 27.8548406],
  [88.1926549, 27.8549289],
  [88.1947948, 27.8548856],
  [88.1955831, 27.8547276],
  [88.1958857, 27.8544926],
  [88.1960668, 27.8543191],
  [88.1961627, 27.854216],
  [88.196595, 27.8532705],
  [88.1967341, 27.8520669],
  [88.1970604, 27.851094],
  [88.1972222, 27.8503482],
  [88.197234, 27.8501473],
  [88.1971254, 27.8496249],
  [88.1970144, 27.849222],
  [88.1968626, 27.8489165],
  [88.1968748, 27.8487585],
  [88.1969705, 27.848399],
  [88.1974526, 27.8474608],
  [88.1975405, 27.8470946],
  [88.1978367, 27.8463318],
  [88.1978635, 27.8458781],
  [88.1986485, 27.845084],
  [88.1989857, 27.8448216],
  [88.1991476, 27.8447187],
  [88.1996358, 27.844622],
  [88.2002028, 27.8443193],
  [88.2008482, 27.843691],
  [88.2010844, 27.84331],
  [88.2012919, 27.8428502],
  [88.2014136, 27.842319],
  [88.2014898, 27.8415175],
  [88.2014248, 27.8401081],
  [88.2015257, 27.8395974],
  [88.2013878, 27.8383691],
  [88.2012702, 27.8379415],
  [88.2011179, 27.8376473],
  [88.2005, 27.8371924],
  [88.2001087, 27.8370334],
  [88.1998918, 27.8366383],
  [88.1997911, 27.8365376],
  [88.1996742, 27.8365274],
  [88.1992654, 27.8367317],
  [88.1970477, 27.8367645],
  [88.1964271, 27.8366932],
  [88.1961644, 27.836639],
  [88.1956805, 27.8364154],
  [88.1950766, 27.8363078],
  [88.1946918, 27.8361104],
  [88.1942413, 27.8357556],
  [88.1938587, 27.8347685],
  [88.1934231, 27.8341564],
  [88.1929866, 27.8337496],
  [88.1914449, 27.8326981],
  [88.1912778, 27.8323228],
  [88.1912385, 27.8316306],
  [88.1911705, 27.8312702],
  [88.1907695, 27.8299337],
  [88.1905242, 27.8294823],
  [88.1899508, 27.8289143],
  [88.1897654, 27.828494],
  [88.1896077, 27.8282856],
  [88.1893363, 27.8281187],
  [88.1891641, 27.8279194],
  [88.1885369, 27.8266006],
  [88.1874207, 27.8255837],
  [88.1867902, 27.8252936],
  [88.1857662, 27.824937],
  [88.1853104, 27.8247333],
  [88.1827353, 27.8234267],
  [88.1824783, 27.8232394],
  [88.1822934, 27.8232161],
  [88.1815679, 27.8229449],
  [88.1806577, 27.8229527],
  [88.1795686, 27.8231379],
  [88.1791287, 27.8230537],
  [88.17773, 27.8224746],
  [88.1751961, 27.8217449],
  [88.1746047, 27.8214296],
  [88.174158, 27.8213635],
  [88.1730615, 27.820863],
  [88.173018, 27.8207618],
  [88.1729883, 27.8206628],
  [88.173003, 27.8205864],
  [88.1730418, 27.8203849],
  [88.173493, 27.8191651],
  [88.1735166, 27.8186979],
  [88.1733006, 27.8180839],
  [88.1732988, 27.8179124],
  [88.1733811, 27.8176613],
  [88.1734939, 27.8175904],
  [88.1736921, 27.8175572],
  [88.174415, 27.8175939],
  [88.1748111, 27.8175657],
  [88.1750863, 27.817437],
  [88.1754727, 27.8171517],
  [88.1759865, 27.8168563],
  [88.1775567, 27.8162903],
  [88.1783064, 27.8161688],
  [88.1787932, 27.8159571],
  [88.1790986, 27.8156905],
  [88.1794332, 27.8151891],
  [88.1800563, 27.8145048],
  [88.1808034, 27.8138419],
  [88.1809726, 27.8137728],
  [88.1820471, 27.8128928],
  [88.1825749, 27.8125499],
  [88.1842851, 27.811211],
  [88.1850116, 27.8108844],
  [88.1851095, 27.8107888],
  [88.1851726, 27.8104048],
  [88.1851682, 27.810001],
  [88.1851249, 27.8099246],
  [88.185138, 27.8088552],
  [88.1853749, 27.8084742],
  [88.1856562, 27.8082642],
  [88.1859987, 27.8078575],
  [88.1860931, 27.8074348],
  [88.1860653, 27.8065169],
  [88.1861101, 27.8060247],
  [88.1860178, 27.8050622],
  [88.1858966, 27.8043571],
  [88.1858948, 27.8038405],
  [88.1859413, 27.8035626],
  [88.1860225, 27.8032168],
  [88.1861627, 27.8030441],
  [88.1864502, 27.8024754],
  [88.1866888, 27.8016093],
  [88.1875466, 27.8007086],
  [88.1877831, 27.800296],
  [88.1879886, 27.7996535],
  [88.1882422, 27.799525],
  [88.1889489, 27.7993678],
  [88.1891969, 27.7990746],
  [88.1893562, 27.7987394],
  [88.1897132, 27.7983212],
  [88.1899951, 27.7981812],
  [88.1902495, 27.7981271],
  [88.190858, 27.798097],
  [88.1911405, 27.7979998],
  [88.1913084, 27.7978157],
  [88.1916365, 27.7970187],
  [88.1917969, 27.7968414],
  [88.1932517, 27.7964273],
  [88.1934647, 27.7964751],
  [88.1936407, 27.7963923],
  [88.1939914, 27.7957531],
  [88.1941592, 27.7955486],
  [88.1946949, 27.7946619],
  [88.1949796, 27.7941316],
  [88.1951966, 27.793568],
  [88.1954839, 27.7932767],
  [88.195646, 27.7931986],
  [88.1962046, 27.7931261],
  [88.196653, 27.7926688],
  [88.1967994, 27.7924284],
  [88.1971372, 27.7914626],
  [88.1971801, 27.79134],
  [88.1971214, 27.7911397],
  [88.1971486, 27.7910131],
  [88.1959893, 27.7896177],
  [88.1955346, 27.7888659],
  [88.1954942, 27.7887106],
  [88.1955137, 27.7885209],
  [88.1953357, 27.7878479],
  [88.1953016, 27.786991],
  [88.1952138, 27.7867278],
  [88.1946431, 27.7863289],
  [88.192452, 27.7850958],
  [88.1916688, 27.7847484],
  [88.1905038, 27.7838019],
  [88.1894266, 27.7827215],
  [88.1880119, 27.7816125],
  [88.1878098, 27.7809284],
  [88.1876862, 27.7806385],
  [88.1874993, 27.7803626],
  [88.1870837, 27.7799375],
  [88.1863015, 27.7786719],
  [88.1862153, 27.7785666],
  [88.1858745, 27.7784748],
  [88.1857319, 27.7783632],
  [88.1852587, 27.7775348],
  [88.1851019, 27.7773985],
  [88.1849805, 27.7773116],
  [88.1843758, 27.7771025],
  [88.1834178, 27.7768828],
  [88.1826901, 27.7768189],
  [88.1821095, 27.7767496],
  [88.1818397, 27.7766572],
  [88.1816601, 27.7764692],
  [88.1812395, 27.7762156],
  [88.1811251, 27.7760767],
  [88.1810876, 27.7758966],
  [88.1813697, 27.7751249],
  [88.1818986, 27.7746038],
  [88.1821492, 27.7742046],
  [88.1821878, 27.7738455],
  [88.18217, 27.773487],
  [88.182082, 27.7732103],
  [88.1817751, 27.7726827],
  [88.1815027, 27.7723512],
  [88.1814652, 27.7721688],
  [88.1814467, 27.7717989],
  [88.1812406, 27.7707472],
  [88.181309, 27.7695058],
  [88.181369, 27.769185],
  [88.1815058, 27.7687055],
  [88.181743, 27.7684125],
  [88.1819743, 27.7681714],
  [88.1825902, 27.767839],
  [88.183109, 27.7673247],
  [88.1832121, 27.7670711],
  [88.1832627, 27.7664729],
  [88.183219, 27.7663605],
  [88.1827076, 27.7659114],
  [88.182291, 27.7653848],
  [88.1821555, 27.764637],
  [88.1819366, 27.7643298],
  [88.1817235, 27.7636142],
  [88.1814401, 27.7629737],
  [88.1806677, 27.7619583],
  [88.1803668, 27.7616338],
  [88.1786778, 27.7602811],
  [88.177425, 27.7593285],
  [88.1772033, 27.759116],
  [88.1768325, 27.7588868],
  [88.1766605, 27.758692],
  [88.1766018, 27.7585414],
  [88.1765378, 27.7581268],
  [88.1766446, 27.7575709],
  [88.1766684, 27.7571308],
  [88.1768307, 27.7564233],
  [88.1769661, 27.756165],
  [88.1771719, 27.7549021],
  [88.1774855, 27.7534692],
  [88.17751, 27.7530922],
  [88.177423, 27.7525515],
  [88.1785896, 27.7504345],
  [88.1784573, 27.7498649],
  [88.1784547, 27.7496235],
  [88.1786232, 27.7491416],
  [88.1786951, 27.7486965],
  [88.178649, 27.7480111],
  [88.1784098, 27.7472236],
  [88.1779423, 27.7467831],
  [88.1775766, 27.7467862],
  [88.1761075, 27.7463521],
  [88.1746275, 27.7448915],
  [88.1743533, 27.7444471],
  [88.1742924, 27.7441408],
  [88.1736886, 27.7435278],
  [88.1725272, 27.7430414],
  [88.1719657, 27.7427551],
  [88.1712883, 27.7424856],
  [88.1704571, 27.7422355],
  [88.170011, 27.7419482],
  [88.1695859, 27.7417984],
  [88.1688139, 27.7416831],
  [88.168639, 27.7415131],
  [88.1679438, 27.7413475],
  [88.1676161, 27.7413503],
  [88.1672895, 27.7414568],
  [88.1670004, 27.7414593],
  [88.1665585, 27.7415668],
  [88.1659041, 27.7416061],
  [88.1654781, 27.7414202],
  [88.1649759, 27.7412868],
  [88.164647, 27.7411182],
  [88.1640447, 27.7406968],
  [88.1631394, 27.7406864],
  [88.1628305, 27.7406033],
  [88.1623869, 27.7405551],
  [88.1601925, 27.740745],
  [88.1597135, 27.7410062],
  [88.1591565, 27.7411485],
  [88.1588671, 27.7411171],
  [88.1586153, 27.7409816],
  [88.1582819, 27.7409844],
  [88.1582512, 27.73934],
  [88.1578388, 27.7385877],
  [88.1575849, 27.7382469],
  [88.1573322, 27.7380279],
  [88.1571952, 27.7378396],
  [88.1567291, 27.7374667],
  [88.1563396, 27.7370752],
  [88.1561243, 27.7367341],
  [88.1560036, 27.7362907],
  [88.1559599, 27.7358105],
  [88.1558387, 27.7352633],
  [88.1555587, 27.7342527],
  [88.1555063, 27.7329515],
  [88.1552314, 27.7324913],
  [88.1550727, 27.7320459],
  [88.1545275, 27.7296343],
  [88.1543501, 27.7274768],
  [88.1541399, 27.725746],
  [88.1542505, 27.7253006],
  [88.154612, 27.7249028],
  [88.1547233, 27.7244552],
  [88.1546539, 27.7233436],
  [88.154411, 27.7222469],
  [88.1544255, 27.7218182],
  [88.1545564, 27.7214245],
  [88.1552249, 27.7208707],
  [88.1554144, 27.7205759],
  [88.1552609, 27.718813],
  [88.1553292, 27.7180228],
  [88.1552672, 27.7176127],
  [88.1545687, 27.7171222],
  [88.1540999, 27.7164742],
  [88.1540016, 27.7162855],
  [88.153936, 27.7155325],
  [88.153378, 27.713755],
  [88.1522603, 27.7119121],
  [88.1516566, 27.7112674],
  [88.1511456, 27.7102768],
  [88.1508138, 27.7098847],
  [88.1495501, 27.7087289],
  [88.149257, 27.7083388],
  [88.1492285, 27.7074298],
  [88.14897, 27.7066446],
  [88.1490199, 27.7059065],
  [88.1489778, 27.7055797],
  [88.1484253, 27.7043164],
  [88.147686, 27.7036029],
  [88.1473138, 27.7031638],
  [88.1473712, 27.7029535],
  [88.1475162, 27.7023996],
  [88.1483381, 27.701126],
  [88.1484777, 27.7009519],
  [88.1487876, 27.7003287],
  [88.1488144, 27.6997445],
  [88.1489646, 27.6990416],
  [88.149383, 27.6961063],
  [88.1505203, 27.6941683],
  [88.1527412, 27.6912329],
  [88.1523071, 27.6888577],
  [88.1519956, 27.6885174],
  [88.1514667, 27.6876825],
  [88.1507754, 27.6859918],
  [88.1504615, 27.68548],
  [88.1502672, 27.6852583],
  [88.1493567, 27.6847695],
  [88.1489121, 27.6846017],
  [88.1486578, 27.684209],
  [88.1486905, 27.6836425],
  [88.1486493, 27.6834037],
  [88.1481496, 27.6816595],
  [88.147915, 27.6812847],
  [88.147428, 27.6807563],
  [88.1466414, 27.6792039],
  [88.146469, 27.6774389],
  [88.1463614, 27.6745272],
  [88.1462591, 27.6738942],
  [88.1459575, 27.6726784],
  [88.1457605, 27.6722514],
  [88.1455397, 27.671432],
  [88.1454169, 27.6707111],
  [88.145258, 27.6702342],
  [88.1452351, 27.6698576],
  [88.1450341, 27.6690516],
  [88.1447994, 27.6668945],
  [88.1444803, 27.6658166],
  [88.1439536, 27.6651193],
  [88.1428246, 27.6639464],
  [88.1423203, 27.6635739],
  [88.1419104, 27.6630787],
  [88.1414841, 27.6627731],
  [88.1411532, 27.6624509],
  [88.1407073, 27.6621455],
  [88.1396179, 27.6610581],
  [88.1392663, 27.6605647],
  [88.13887, 27.6594873],
  [88.1384818, 27.6591295],
  [88.1382464, 27.6587208],
  [88.1370613, 27.657686],
  [88.1365766, 27.6573629],
  [88.1357445, 27.6569591],
  [88.1349341, 27.6567424],
  [88.1340081, 27.6565627],
  [88.1332723, 27.6561559],
  [88.1327129, 27.6560251],
  [88.1322492, 27.6558394],
  [88.131844, 27.6557908],
  [88.1311293, 27.6555237],
  [88.1308953, 27.6552503],
  [88.1306582, 27.6546183],
  [88.1300546, 27.6540051],
  [88.1296088, 27.6537019],
  [88.1291393, 27.65295],
  [88.1286177, 27.6527331],
  [88.1278456, 27.6525499],
  [88.1274778, 27.6523815],
  [88.1272455, 27.6522119],
  [88.1269916, 27.651853],
  [88.1264494, 27.6515325],
  [88.1261383, 27.6512779],
  [88.1256544, 27.6509727],
  [88.1242367, 27.6497682],
  [88.123964, 27.6494275],
  [88.1234975, 27.6490207],
  [88.1222122, 27.6475737],
  [88.1222264, 27.6470412],
  [88.1220307, 27.646736],
  [88.1216237, 27.646446],
  [88.1208882, 27.6460594],
  [88.1199225, 27.6457581],
  [88.1197059, 27.6452635],
  [88.1197203, 27.6448167],
  [88.1199036, 27.643906],
  [88.1199915, 27.6430841],
  [88.1201393, 27.6424828],
  [88.1202529, 27.6422586],
  [88.1203063, 27.6418475],
  [88.120186, 27.6413499],
  [88.1201627, 27.6409914],
  [88.1204232, 27.6400801],
  [88.1204955, 27.6396509],
  [88.1203126, 27.6386755],
  [88.1197189, 27.6370853],
  [88.1192113, 27.6363697],
  [88.1187582, 27.6353446],
  [88.1183295, 27.6348518],
  [88.1181908, 27.6344761],
  [88.1177438, 27.6321131],
  [88.1171059, 27.6299773],
  [88.1171398, 27.6295146],
  [88.1174411, 27.6288759],
  [88.1177278, 27.6286683],
  [88.1182838, 27.6284247],
  [88.1187806, 27.6280778],
  [88.1192408, 27.6279364],
  [88.1193509, 27.6274392],
  [88.119038, 27.6269274],
  [88.1186303, 27.6265697],
  [88.1179084, 27.6255648],
  [88.1176331, 27.6250188],
  [88.1168321, 27.6238431],
  [88.1157937, 27.6220331],
  [88.1154242, 27.6216255],
  [88.1151499, 27.621181],
  [88.1151794, 27.6202738],
  [88.1151376, 27.6199651],
  [88.1147396, 27.6186146],
  [88.1143049, 27.6175194],
  [88.1141661, 27.6151901],
  [88.1140279, 27.6148663],
  [88.1136836, 27.6131883],
  [88.1132307, 27.6121632],
  [88.1133226, 27.6117338],
  [88.1136024, 27.6114278],
  [88.1135028, 27.6108535],
  [88.1125001, 27.609769],
  [88.1114851, 27.6089037],
  [88.1109621, 27.6085311],
  [88.1105904, 27.607952],
  [88.1099695, 27.6074088],
  [88.1095024, 27.606862],
  [88.1087487, 27.606577],
  [88.1084377, 27.6062546],
  [88.1082406, 27.6057937],
  [88.1070853, 27.6039214],
  [88.1065718, 27.6036593],
  [88.1061168, 27.6032343],
  [88.1057228, 27.6029689],
  [88.1054796, 27.6027024],
  [88.1041815, 27.6021781],
  [88.1037, 27.6020736],
  [88.1028551, 27.601787],
  [88.1021925, 27.601684],
  [88.1011975, 27.6013986],
  [88.1006845, 27.6011884],
  [88.1002002, 27.6008696],
  [88.0998362, 27.6005521],
  [88.0990799, 27.6000753],
  [88.098204, 27.5996536],
  [88.0978107, 27.5993905],
  [88.0974488, 27.599285],
  [88.0969957, 27.5990472],
  [88.0966037, 27.5989714],
  [88.0953071, 27.59858],
  [88.0947066, 27.5986096],
  [88.094164, 27.5984808],
  [88.0935308, 27.5982714],
  [88.0927442, 27.5977948],
  [88.0922286, 27.5973183],
  [88.0918324, 27.5967574],
  [88.0914375, 27.596386],
  [88.091044, 27.5960958],
  [88.0895029, 27.5953025],
  [88.089016, 27.5947175],
  [88.0888628, 27.5943984],
  [88.0884977, 27.5939726],
  [88.0875876, 27.5930953],
  [88.0865874, 27.5923271],
  [88.0854922, 27.5909685],
  [88.0849452, 27.5903839],
  [88.0846398, 27.5899058],
  [88.0840591, 27.5889176],
  [88.0839663, 27.5886251],
  [88.083721, 27.5881442],
  [88.0834778, 27.5878776],
  [88.0831722, 27.5873724],
  [88.0829879, 27.5869723],
  [88.0827436, 27.5865974],
  [88.0826509, 27.5863048],
  [88.0820106, 27.5853713],
  [88.0818284, 27.5851855],
  [88.0812442, 27.5839041],
  [88.0804811, 27.5827053],
  [88.0800811, 27.581745],
  [88.0798623, 27.5808624],
  [88.0796777, 27.5804351],
  [88.0789783, 27.5796645],
  [88.0785568, 27.5795324],
  [88.0783384, 27.578758],
  [88.0782725, 27.5781697],
  [88.0782979, 27.5776596],
  [88.0782336, 27.5772337],
  [88.0777737, 27.5763529],
  [88.0774722, 27.5761951],
  [88.0770781, 27.5759026],
  [88.0768324, 27.5753698],
  [88.0768298, 27.5751013],
  [88.0766455, 27.5747012],
  [88.0765214, 27.5742464],
  [88.076277, 27.5738467],
  [88.0753961, 27.572888],
  [88.0753336, 27.5726493],
  [88.0750589, 27.5722499],
  [88.0751164, 27.5719809],
  [88.0757715, 27.5713329],
  [88.076247, 27.5707674],
  [88.0764861, 27.5706302],
  [88.0769914, 27.5700645],
  [88.0777366, 27.5694429],
  [88.0787536, 27.5689792],
  [88.0792613, 27.5686008],
  [88.0794073, 27.5681981],
  [88.079763, 27.5676854],
  [88.0799682, 27.5671762],
  [88.0804126, 27.5664756],
  [88.0807098, 27.5661259],
  [88.080974, 27.5655079],
  [88.0809091, 27.5650256],
  [88.0805767, 27.5648139],
  [88.0804227, 27.5644677],
  [88.079788, 27.5640958],
  [88.0797839, 27.5636695],
  [88.0790459, 27.5619877],
  [88.0786513, 27.5615621],
  [88.0780417, 27.5607095],
  [88.0771929, 27.5599129],
  [88.0766782, 27.5595672],
  [88.0758908, 27.5589055],
  [88.0749304, 27.5584144],
  [88.0745442, 27.5582662],
  [88.0739125, 27.5576078],
  [88.0730897, 27.557014],
  [88.0726328, 27.5570176],
  [88.0720543, 27.5568709],
  [88.0708028, 27.5567091],
  [88.0701499, 27.5563283],
  [88.0695723, 27.5562899],
  [88.0690887, 27.5560161],
  [88.0687467, 27.5553983],
  [88.0688132, 27.5548834],
  [88.068977, 27.5544106],
  [88.0689676, 27.5534451],
  [88.0687442, 27.5527181],
  [88.0683777, 27.5520576],
  [88.0682287, 27.551567],
  [88.0679837, 27.5510973],
  [88.0679783, 27.5505401],
  [88.0683594, 27.5501717],
  [88.0685703, 27.5495925],
  [88.0684556, 27.547687],
  [88.0684963, 27.5469151],
  [88.068397, 27.5465933],
  [88.0673291, 27.5455727],
  [88.0670387, 27.5453832],
  [88.0664838, 27.5451302],
  [88.0656162, 27.5449225],
  [88.0650147, 27.5448843],
  [88.0637521, 27.5435448],
  [88.063431, 27.5426674],
  [88.0629415, 27.541771],
  [88.0625535, 27.541431],
  [88.0616598, 27.540946],
  [88.0610304, 27.5405019],
  [88.0607635, 27.5402467],
  [88.0603223, 27.5393702],
  [88.059925, 27.538067],
  [88.0596577, 27.5377035],
  [88.0595293, 27.5368472],
  [88.0590885, 27.5360158],
  [88.0588652, 27.5352888],
  [88.0587805, 27.5339831],
  [88.0589188, 27.5333594],
  [88.0589145, 27.5329105],
  [88.0592922, 27.5321789],
  [88.0596961, 27.531684],
  [88.0606966, 27.5307762],
  [88.0614618, 27.5303417],
  [88.0624451, 27.5301627],
  [88.0631189, 27.5302207],
  [88.0639593, 27.5301082],
  [88.0646067, 27.529995],
  [88.0651333, 27.5297563],
  [88.0657741, 27.5288941],
  [88.0659157, 27.5286155],
  [88.0668155, 27.5272572],
  [88.0670487, 27.5265064],
  [88.0664954, 27.5264678],
  [88.0660169, 27.5266429],
  [88.0655597, 27.5266036],
  [88.0641414, 27.5266144],
  [88.0635885, 27.5265532],
  [88.0630577, 27.5263655],
  [88.0625523, 27.526304],
  [88.0620939, 27.526136],
  [88.061271, 27.5254993],
  [88.0610758, 27.5252007],
  [88.0605418, 27.5246701],
  [88.0598367, 27.5238407],
  [88.0594666, 27.5227922],
  [88.0590952, 27.5221173],
  [88.0588794, 27.521725],
  [88.0579685, 27.5194826],
  [88.0572827, 27.5180958],
  [88.0570808, 27.5170912],
  [88.0570525, 27.5166402],
  [88.0571692, 27.516276],
  [88.0578812, 27.5153908],
  [88.0584779, 27.5149372],
  [88.0592923, 27.514631],
  [88.0598891, 27.514261],
  [88.0602227, 27.5139584],
  [88.0607244, 27.5136342],
  [88.0610576, 27.5132887],
  [88.0614365, 27.5126857],
  [88.0614087, 27.5123001],
  [88.0610683, 27.5118966],
  [88.0610424, 27.5117028],
  [88.0607501, 27.5112989],
  [88.0602186, 27.5110232],
  [88.0596656, 27.5110071],
  [88.0591119, 27.5108602],
  [88.0585807, 27.510607],
  [88.0580239, 27.5102052],
  [88.0574424, 27.5096523],
  [88.0570948, 27.5084976],
  [88.0564615, 27.5076248],
  [88.0563134, 27.5072175],
  [88.0559972, 27.5068342],
  [88.0546417, 27.505775],
  [88.054705, 27.5048518],
  [88.054175, 27.5047272],
  [88.0537442, 27.5049245],
  [88.0532159, 27.5049714],
  [88.0524712, 27.5049545],
  [88.0511943, 27.5046438],
  [88.0506898, 27.5046047],
  [88.050207, 27.504394],
  [88.0496271, 27.5040758],
  [88.0494817, 27.5039483],
  [88.0495744, 27.5035843],
  [88.0494999, 27.5033277],
  [88.0487943, 27.5024328],
  [88.0482407, 27.5022859],
  [88.0477372, 27.5024182],
  [88.0467474, 27.5019564],
  [88.0464087, 27.5016589],
  [88.0455427, 27.5005148],
  [88.0456218, 27.5004533],
  [88.0455974, 27.500009],
  [88.0454298, 27.4997508],
  [88.0450986, 27.4995728],
  [88.0447094, 27.4990862],
  [88.0444303, 27.4985987],
  [88.0442551, 27.4975216],
  [88.044104, 27.4969993],
  [88.0436016, 27.4961706],
  [88.0435438, 27.4958777],
  [88.0435947, 27.4954374],
  [88.0439196, 27.4949454],
  [88.044351, 27.4940758],
  [88.044434, 27.4931615],
  [88.0443168, 27.4923953],
  [88.0441667, 27.4919722],
  [88.0439241, 27.4915319],
  [88.0437316, 27.4905045],
  [88.0436569, 27.4884159],
  [88.0437267, 27.4860961],
  [88.0438088, 27.4851479],
  [88.0440066, 27.4847223],
  [88.044235, 27.4837256],
  [88.0445566, 27.4828907],
  [88.0451325, 27.4818576],
  [88.0451298, 27.4815801],
  [88.0446079, 27.4805553],
  [88.0445135, 27.4802627],
  [88.0440664, 27.4794651],
  [88.0438229, 27.4788623],
  [88.0438213, 27.4786841],
  [88.0445085, 27.4777968],
  [88.0449053, 27.4771576],
  [88.0452653, 27.4765006],
  [88.0454975, 27.4759123],
  [88.0459505, 27.4754012],
  [88.0471166, 27.4747901],
  [88.0475324, 27.4742951],
  [88.0479081, 27.4733628],
  [88.0483228, 27.4726896],
  [88.048834, 27.4725075],
  [88.0496928, 27.472386],
  [88.050333, 27.4723473],
  [88.0510435, 27.4719832],
  [88.0513517, 27.4716718],
  [88.0519832, 27.4706541],
  [88.0533998, 27.4694679],
  [88.0536358, 27.4692856],
  [88.0546928, 27.4687881],
  [88.0555861, 27.4684384],
  [88.0560779, 27.4681571],
  [88.0565857, 27.4676975],
  [88.0569294, 27.4672685],
  [88.0570362, 27.4669271],
  [88.0570876, 27.4665499],
  [88.0570836, 27.4661258],
  [88.0572817, 27.4658129],
  [88.0578612, 27.4651227],
  [88.0591661, 27.4637749],
  [88.0592912, 27.463431],
  [88.0592694, 27.4630724],
  [88.0595015, 27.4624818],
  [88.0603142, 27.461316],
  [88.0610419, 27.4609044],
  [88.0616601, 27.4604259],
  [88.0623129, 27.4598005],
  [88.0628037, 27.4594854],
  [88.0636062, 27.4591702],
  [88.0641184, 27.4591008],
  [88.0647596, 27.4591771],
  [88.0652349, 27.4591577],
  [88.0657279, 27.4590073],
  [88.0664736, 27.4585097],
  [88.0669089, 27.4580823],
  [88.0670156, 27.4578039],
  [88.0669215, 27.4574798],
  [88.066202, 27.4568965],
  [88.0660611, 27.4555912],
  [88.0659461, 27.4550687],
  [88.0657059, 27.4548111],
  [88.0649702, 27.4544399],
  [88.0645281, 27.4541658],
  [88.0643802, 27.4539707],
  [88.0645974, 27.4537411],
  [88.0655242, 27.453082],
  [88.0662339, 27.4526502],
  [88.0665229, 27.4521923],
  [88.0668877, 27.4502289],
  [88.0671036, 27.4498031],
  [88.0689258, 27.4489566],
  [88.0698346, 27.4483292],
  [88.0701793, 27.4480175],
  [88.0703056, 27.4478044],
  [88.0704835, 27.4473112],
  [88.0705175, 27.4470335],
  [88.0707882, 27.4466569],
  [88.0712613, 27.4463419],
  [88.0720442, 27.4459117],
  [88.0723348, 27.4456974],
  [88.0723693, 27.4454693],
  [88.0722734, 27.4450278],
  [88.0720324, 27.4446868],
  [88.0717556, 27.4445107],
  [88.0710571, 27.4441889],
  [88.0709282, 27.444059],
  [88.0709068, 27.4437501],
  [88.0708127, 27.4434891],
  [88.0702229, 27.4430357],
  [88.0698871, 27.4423704],
  [88.0694621, 27.4419653],
  [88.0691839, 27.4415591],
  [88.0691072, 27.4412168],
  [88.0689576, 27.4409066],
  [88.0687001, 27.4407619],
  [88.0679385, 27.4395923],
  [88.0677904, 27.4394273],
  [88.0676079, 27.439224],
  [88.0678183, 27.4385144],
  [88.0678352, 27.4384812],
  [88.0679443, 27.4382697],
  [88.0680879, 27.4379758],
  [88.0688667, 27.4371847],
  [88.0699562, 27.4362948],
  [88.0709014, 27.4355853],
  [88.0716989, 27.4348459],
  [88.0726618, 27.4341346],
  [88.073557, 27.4337208],
  [88.0743923, 27.4333226],
  [88.0746125, 27.4333221],
  [88.075163, 27.4333988],
  [88.0757663, 27.4334248],
  [88.0763691, 27.4333389],
  [88.0772074, 27.4330234],
  [88.0780454, 27.4326085],
  [88.0784809, 27.4322126],
  [88.0786057, 27.4319183],
  [88.0786385, 27.4315083],
  [88.0786542, 27.4312479],
  [88.0788516, 27.4308064],
  [88.079233, 27.4305102],
  [88.0799795, 27.4301773],
  [88.0800878, 27.4300117],
  [88.0800856, 27.4297839],
  [88.0800473, 27.4296226],
  [88.0799313, 27.429031],
  [88.0798354, 27.4284953],
  [88.0798681, 27.4280867],
  [88.0800081, 27.4274652],
  [88.0799859, 27.427075],
  [88.0799292, 27.426895],
  [88.0795624, 27.4267512],
  [88.0788842, 27.426691],
  [88.0786619, 27.4266937],
  [88.0782076, 27.4267301],
  [88.0774956, 27.426898],
  [88.0764344, 27.4269062],
  [88.0756628, 27.4265377],
  [88.0751864, 27.4264917],
  [88.0747466, 27.4265937],
  [88.0744193, 27.4266443],
  [88.0741443, 27.426581],
  [88.0737033, 27.4264039],
  [88.0733371, 27.4263751],
  [88.0726971, 27.4264116],
  [88.0725508, 27.4263812],
  [88.072219, 27.4261874],
  [88.0717971, 27.4260282],
  [88.0715957, 27.4260771],
  [88.0712129, 27.426211],
  [88.0707193, 27.4262148],
  [88.070299, 27.4262834],
  [88.0701511, 27.4261537],
  [88.06938, 27.4258347],
  [88.0691274, 27.4258366],
  [88.0684106, 27.4259234],
  [88.068154, 27.4258599],
  [88.0676569, 27.425505],
  [88.0669972, 27.4254604],
  [88.066885, 27.4252647],
  [88.0667172, 27.4248737],
  [88.0664406, 27.4246322],
  [88.0658711, 27.4243748],
  [88.0653184, 27.4240203],
  [88.063073, 27.4224212],
  [88.0629418, 27.422308],
  [88.0625555, 27.4220989],
  [88.0615099, 27.4218113],
  [88.0602971, 27.4212994],
  [88.0600577, 27.4211207],
  [88.0597048, 27.4206977],
  [88.0593179, 27.4202938],
  [88.0579851, 27.4186547],
  [88.0578192, 27.418559],
  [88.0574717, 27.4185774],
  [88.0569964, 27.4187442],
  [88.0567609, 27.4187949],
  [88.0563766, 27.4187978],
  [88.0561001, 27.4186374],
  [88.0555829, 27.4184757],
  [88.0552376, 27.4183326],
  [88.0542051, 27.4174921],
  [88.0539272, 27.4171174],
  [88.0538294, 27.4164662],
  [88.053419, 27.4159936],
  [88.0529228, 27.4154442],
  [88.0525149, 27.4148111],
  [88.0520725, 27.4144067],
  [88.0514637, 27.4139368],
  [88.0510564, 27.4134345],
  [88.0504131, 27.4131123],
  [88.0502826, 27.4128854],
  [88.0503164, 27.4125738],
  [88.0505507, 27.4122291],
  [88.0505473, 27.4118704],
  [88.0503021, 27.4110713],
  [88.0502245, 27.4106161],
  [88.0499625, 27.4099796],
  [88.0498114, 27.4094315],
  [88.0495843, 27.4086762],
  [88.0494069, 27.4073554],
  [88.0492581, 27.4070632],
  [88.0478799, 27.4065677],
  [88.0476972, 27.4064703],
  [88.0469763, 27.4058228],
  [88.0467745, 27.4055951],
  [88.046827, 27.4053353],
  [88.0469891, 27.4051043],
  [88.0473504, 27.4046116],
  [88.0474359, 27.403959],
  [88.0473938, 27.4033862],
  [88.0476268, 27.4027624],
  [88.0479297, 27.4020443],
  [88.0482896, 27.4014054],
  [88.0482859, 27.4010128],
  [88.0481706, 27.4003775],
  [88.0479486, 27.3999912],
  [88.0478717, 27.3997751],
  [88.0473552, 27.3992894],
  [88.0473343, 27.399012],
  [88.0471093, 27.3984091],
  [88.0467005, 27.3977443],
  [88.0463447, 27.39688],
  [88.0461202, 27.3963769],
  [88.0457137, 27.3958904],
  [88.045619, 27.395564],
  [88.0455936, 27.3949409],
  [88.0455873, 27.3941428],
  [88.0456543, 27.3934389],
  [88.0457008, 27.3926394],
  [88.0456446, 27.392413],
  [88.0454945, 27.3920701],
  [88.0452726, 27.3917943],
  [88.0451226, 27.3914367],
  [88.0448586, 27.3905723],
  [88.0446526, 27.3901001],
  [88.0446855, 27.3896887],
  [88.0448425, 27.3888578],
  [88.0450211, 27.3884323],
  [88.0449821, 27.3881388],
  [88.0448872, 27.3877655],
  [88.0447199, 27.3874565],
  [88.0446445, 27.3872935],
  [88.0446415, 27.3869968],
  [88.044692, 27.3864922],
  [88.0446147, 27.3860686],
  [88.0442613, 27.3855964],
  [88.0440401, 27.3852404],
  [88.0439823, 27.3849802],
  [88.0439626, 27.384783],
  [88.0443558, 27.3838009],
  [88.0446066, 27.3833577],
  [88.0448054, 27.382965],
  [88.0449295, 27.3825873],
  [88.0447932, 27.3816588],
  [88.0448018, 27.3806299],
  [88.0448713, 27.380221],
  [88.0446644, 27.3796518],
  [88.0443494, 27.3792119],
  [88.044033, 27.3786277],
  [88.0437718, 27.3780899],
  [88.0435653, 27.377537],
  [88.0432139, 27.3768252],
  [88.0429688, 27.3763164],
  [88.0429088, 27.3759076],
  [88.0428876, 27.3755337],
  [88.04285, 27.3753697],
  [88.0427022, 27.3751752],
  [88.0422784, 27.3747381],
  [88.0421475, 27.3745604],
  [88.0420891, 27.3741999],
  [88.0418464, 27.3736467],
  [88.0416948, 27.3731109],
  [88.0415404, 27.3722141],
  [88.0415529, 27.3716077],
  [88.041637, 27.3707758],
  [88.0416691, 27.3703676],
  [88.0418492, 27.3700391],
  [88.0421026, 27.3697755],
  [88.0430834, 27.3690665],
  [88.0435904, 27.3684919],
  [88.0440601, 27.3678156],
  [88.0443299, 27.3673763],
  [88.0445261, 27.3668667],
  [88.0447041, 27.3663267],
  [88.0448658, 27.366048],
  [88.045375, 27.3656694],
  [88.0460096, 27.3651415],
  [88.046516, 27.3645827],
  [88.0470205, 27.3637464],
  [88.047109, 27.3634208],
  [88.0471084, 27.3632701],
  [88.0471384, 27.3626323],
  [88.0471357, 27.362358],
  [88.0474599, 27.3618344],
  [88.0478929, 27.361258],
  [88.048798, 27.3601562],
  [88.0493034, 27.3595666],
  [88.0500097, 27.3588416],
  [88.0506827, 27.358462],
  [88.0512487, 27.3583269],
  [88.0517976, 27.3583236],
  [88.0530048, 27.3583791],
  [88.0534422, 27.3582788],
  [88.0546254, 27.357681],
  [88.0554971, 27.3570698],
  [88.0560417, 27.3566099],
  [88.0568071, 27.3563266],
  [88.057479, 27.3558319],
  [88.0577297, 27.3552908],
  [88.0578714, 27.3547821],
  [88.0582108, 27.3540141],
  [88.0584611, 27.3533585],
  [88.0585447, 27.3527199],
  [88.0585601, 27.3524024],
  [88.0585945, 27.3520354],
  [88.0586825, 27.351658],
  [88.0589176, 27.3513471],
  [88.0593702, 27.3509173],
  [88.0595675, 27.3504758],
  [88.0596719, 27.3500826],
  [88.0598519, 27.3496253],
  [88.060158, 27.3491166],
  [88.0605729, 27.3485256],
  [88.0607173, 27.3482613],
  [88.0610009, 27.3476919],
  [88.0612759, 27.3471933],
  [88.0615071, 27.3468039],
  [88.0615597, 27.3465575],
  [88.0613375, 27.3462998],
  [88.0611704, 27.3460055],
  [88.0611858, 27.3456963],
  [88.0612396, 27.3455823],
  [88.061344, 27.3450588],
  [88.0616486, 27.3443526],
  [88.0621343, 27.3435502],
  [88.0623711, 27.343483],
  [88.0628299, 27.343642],
  [88.0630314, 27.3436404],
  [88.0633541, 27.3429702],
  [88.063949, 27.3420993],
  [88.0645833, 27.3415236],
  [88.0646727, 27.3412928],
  [88.0647195, 27.3404442],
  [88.0648056, 27.3399381],
  [88.0653027, 27.3383505],
  [88.0663448, 27.3364316],
  [88.0664692, 27.3361035],
  [88.0663958, 27.3360229],
  [88.0658635, 27.3360416],
  [88.0654437, 27.3358537],
  [88.0651456, 27.3356233],
  [88.0638066, 27.3346513],
  [88.0632946, 27.3341633],
  [88.0629403, 27.3336105],
  [88.0623493, 27.3327333],
  [88.0615229, 27.3318266],
  [88.0607564, 27.3313143],
  [88.0600509, 27.3301781],
  [88.0599561, 27.3298359],
  [88.0600251, 27.3293931],
  [88.0599998, 27.3286601],
  [88.0594107, 27.328272],
  [88.0590263, 27.3281598],
  [88.0582387, 27.3280191],
  [88.0577801, 27.327876],
  [88.0570637, 27.3275227],
  [88.0567676, 27.3271323],
  [88.0565994, 27.3267749],
  [88.0558084, 27.32621],
  [88.055515, 27.3260972],
  [88.0543424, 27.3258443],
  [88.0538465, 27.325636],
  [88.0531465, 27.3250705],
  [88.0527613, 27.3249425],
  [88.0523757, 27.3246995],
  [88.0522046, 27.324033],
  [88.0522186, 27.3235771],
  [88.0520855, 27.3230524],
  [88.0519003, 27.3227763],
  [88.0512177, 27.3221136],
  [88.050848, 27.3216742],
  [88.0505105, 27.3207788],
  [88.0495299, 27.3195137],
  [88.0492863, 27.3189266],
  [88.0492961, 27.3180286],
  [88.0495036, 27.3167207],
  [88.0495478, 27.3156442],
  [88.0494338, 27.3151374],
  [88.0488834, 27.3149633],
  [88.0487364, 27.3148494],
  [88.0486426, 27.3146042],
  [88.0487298, 27.3141478],
  [88.0485572, 27.3133165],
  [88.0483887, 27.3128598],
  [88.0469875, 27.3115798],
  [88.0465803, 27.3110459],
  [88.0449275, 27.3102731],
  [88.044576, 27.30982],
  [88.044279, 27.3093303],
  [88.0442207, 27.308972],
  [88.0440517, 27.3084521],
  [88.0430366, 27.307397],
  [88.0427592, 27.3070404],
  [88.0422919, 27.3059496],
  [88.0417163, 27.304959],
  [88.0413827, 27.3045373],
  [88.0409732, 27.3036898],
  [88.0408996, 27.3016823],
  [88.0415486, 27.3007299],
  [88.0421055, 27.299715],
  [88.0423729, 27.2990091],
  [88.0430367, 27.2976166],
  [88.0433957, 27.2969776],
  [88.0434479, 27.2966839],
  [88.0436982, 27.2960458],
  [88.0438897, 27.2950472],
  [88.0438871, 27.2947697],
  [88.0441199, 27.2942784],
  [88.0445383, 27.2940632],
  [88.044665, 27.2938998],
  [88.0444239, 27.2935744],
  [88.0433549, 27.292619],
  [88.042836, 27.2918062],
  [88.0417379, 27.2916519],
  [88.0410601, 27.2914765],
  [88.0405226, 27.291591],
  [88.0396177, 27.2919046],
  [88.0389332, 27.2920902],
  [88.038368, 27.2920763],
  [88.0379831, 27.2918987],
  [88.037536, 27.291541],
  [88.0370218, 27.2905002],
  [88.0366143, 27.290052],
  [88.0361695, 27.289947],
  [88.0357053, 27.2899324],
  [88.0351595, 27.2898281],
  [88.0349355, 27.2896312],
  [88.03467, 27.2892542],
  [88.0346246, 27.2887153],
  [88.0341968, 27.2882672],
  [88.0337263, 27.2875668],
  [88.0334422, 27.2874245],
  [88.0324508, 27.2871272],
  [88.0319838, 27.2868057],
  [88.0315767, 27.2864117],
  [88.0314691, 27.2859077],
  [88.0314863, 27.2853677],
  [88.03162, 27.2845365],
  [88.0329063, 27.2818421],
  [88.0329035, 27.2815353],
  [88.0324527, 27.2807805],
  [88.0323001, 27.2795746],
  [88.0321535, 27.278944],
  [88.0319876, 27.2784782],
  [88.0310691, 27.2773117],
  [88.0310009, 27.2764842],
  [88.0311122, 27.2754185],
  [88.0308585, 27.2741952],
  [88.0303661, 27.2732964],
  [88.0301348, 27.2722354],
  [88.0301536, 27.2720909],
  [88.0305945, 27.2717808],
  [88.0308318, 27.271294],
  [88.0309279, 27.2707698],
  [88.0309431, 27.2702282],
  [88.0311174, 27.269417],
  [88.0310307, 27.2687678],
  [88.0310225, 27.2678857],
  [88.03114, 27.2674878],
  [88.0316367, 27.2666538],
  [88.0317324, 27.26615],
  [88.0317472, 27.2655723],
  [88.0316626, 27.2648848],
  [88.0315368, 27.2645834],
  [88.0309076, 27.264191],
  [88.0306426, 27.26385],
  [88.0304975, 27.2634539],
  [88.0305548, 27.2630925],
  [88.0308725, 27.262569],
  [88.0310287, 27.2619903],
  [88.0309823, 27.2613408],
  [88.0306318, 27.2604973],
  [88.0305886, 27.2601908],
  [88.0306411, 27.2593083],
  [88.0306164, 27.2588211],
  [88.0307545, 27.2585313],
  [88.0308317, 27.2581517],
  [88.0307453, 27.2575409],
  [88.0304342, 27.2562459],
  [88.0301342, 27.2555329],
  [88.0300498, 27.2550778],
  [88.0301788, 27.2546233],
  [88.0304667, 27.2539534],
  [88.0308871, 27.253138],
  [88.0310165, 27.2526588],
  [88.0308481, 27.2518478],
  [88.030667, 27.2504796],
  [88.0307175, 27.2502062],
  [88.0303271, 27.2501708],
  [88.0293171, 27.2502075],
  [88.0289382, 27.250181],
  [88.0281037, 27.249887],
  [88.0277968, 27.2497291],
  [88.0275526, 27.2495323],
  [88.0274776, 27.2493366],
  [88.0274422, 27.2489217],
  [88.0273333, 27.2485367],
  [88.0269892, 27.2477586],
  [88.0268474, 27.2472339],
  [88.0267468, 27.2465849],
  [88.0262563, 27.2458823],
  [88.0259798, 27.2455933],
  [88.0255107, 27.244956],
  [88.0250916, 27.2442551],
  [88.0248851, 27.2436926],
  [88.0247345, 27.2434523],
  [88.0242278, 27.2424113],
  [88.0240777, 27.2422184],
  [88.0243794, 27.2413995],
  [88.0244959, 27.2408819],
  [88.0244083, 27.2401848],
  [88.0243528, 27.2399331],
  [88.0243499, 27.2396195],
  [88.0248229, 27.2384158],
  [88.024701, 27.2375792],
  [88.0246794, 27.2374309],
  [88.0244241, 27.2377216],
  [88.0243734, 27.2377793],
  [88.0242132, 27.2379261],
  [88.0240299, 27.2379593],
  [88.0237953, 27.2380017],
  [88.0234078, 27.2381125],
  [88.0226272, 27.2380347],
  [88.022062, 27.2379051],
  [88.0215897, 27.2378257],
  [88.0209426, 27.2376499],
  [88.0204966, 27.2374614],
  [88.0202535, 27.2373053],
  [88.0197383, 27.2368533],
  [88.019317, 27.2363983],
  [88.0190988, 27.2360637],
  [88.0189036, 27.2352845],
  [88.0188735, 27.2349706],
  [88.0188927, 27.234082],
  [88.0191951, 27.2333351],
  [88.0193144, 27.2331291],
  [88.0196421, 27.2321881],
  [88.0196223, 27.2316973],
  [88.0195953, 27.23148],
  [88.0194042, 27.2311577],
  [88.0192011, 27.2309302],
  [88.0190908, 27.2307504],
  [88.0189792, 27.2303429],
  [88.0189735, 27.2297077],
  [88.0189839, 27.2293433],
  [88.0190883, 27.2290199],
  [88.018923, 27.2285381],
  [88.0188668, 27.2283221],
  [88.0188768, 27.2279497],
  [88.0188616, 27.2277581],
  [88.0185388, 27.2262578],
  [88.0185481, 27.2258246],
  [88.0186401, 27.2255429],
  [88.0187445, 27.225223],
  [88.0184584, 27.2248393],
  [88.0182405, 27.2246747],
  [88.0180792, 27.2244946],
  [88.0179263, 27.2239903],
  [88.0179379, 27.2238097],
  [88.0178692, 27.2236049],
  [88.0175007, 27.2230435],
  [88.0172038, 27.2228406],
  [88.0167843, 27.2226043],
  [88.0164857, 27.2223041],
  [88.0159894, 27.2224769],
  [88.0157467, 27.2223591],
  [88.0155301, 27.2220368],
  [88.0152673, 27.2214954],
  [88.0146943, 27.2205505],
  [88.0143832, 27.2203361],
  [88.0140428, 27.2199546],
  [88.0137306, 27.2195489],
  [88.0135792, 27.2191281],
  [88.0133045, 27.2185667],
  [88.0131134, 27.2181116],
  [88.0129344, 27.2177384],
  [88.0127473, 27.2174352],
  [88.012393, 27.216828],
  [88.0121831, 27.2165157],
  [88.0120333, 27.2162057],
];

export const BLOCKED_SESSION_KEY = "s7654fjhfjhj";
export const VALID_LOCATION_SESSION_DURATION = "60000"; // 1 minute

export const UPDATE_PASSWORD_FLAG_KEY = "psdupdt";

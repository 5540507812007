import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { logoutUser, updatePassword } from "../../../../redux/auth/actions";
import { Toaster } from "../../../../utils/toaster";
import { APP_CONST, UPDATE_PASSWORD_FLAG_KEY } from "../../../../config/const";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const GlobalUpdatePasswordModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const isUpdateFlag =
      localStorage.getItem(UPDATE_PASSWORD_FLAG_KEY) === "true";
    if (!isUpdateFlag) {
      navigate("/home");
    }
  }, []);

  const validateData = (data) => {
    const dataToValidate = data ? data : formData;
    const { oldPassword, newPassword, confirmPassword } = dataToValidate;
    const newErrors = {};
    if (!oldPassword || oldPassword === "") {
      newErrors.oldPassword = "Temporary password cannot be empty";
    }
    if (!newPassword || newPassword === "") {
      newErrors.newPassword = "Permanent password cannot be empty";
    }
    if (!confirmPassword || confirmPassword === "") {
      newErrors.confirmPassword = "Confirm permanent password cannot be empty";
    } else if (newPassword !== confirmPassword) {
      newErrors.confirmPassword = "Confirm password must match";
    }
    setErrors(newErrors);
    return newErrors;
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const data = { ...formData, [name]: value };
    validateData(data);
    setFormData(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.keys(validateData(formData)).length === 0) {
      let payload = { ...formData };
      delete payload?.confirmPassword;
      const response = await updatePassword(formData);
      if (response && response?.data) {
        localStorage.removeItem(UPDATE_PASSWORD_FLAG_KEY);
        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, response.data.data.message);
        // dispatch(logoutUser());
      }
    }
  };

  return (
    <Modal
      show={true}
      id="loginModal"
      className={"steps-canvas lgn-reg-modal login"}
      onHide={() => {
        console.log("working");
      }}
      centered
      placement={"end"}
    >
      <Modal.Header
        closeButton={false}
        // onClick={() => }
        closeVariant="white"
      ></Modal.Header>
      <Modal.Body>
        <div className={`login-section`}>
          <Modal.Title>Update password</Modal.Title>
          <div className="welcome"></div>
          <div className="form-steps">
            <Form onSubmit={handleSubmit}>
              <Form.Group
                className="position-relative"
                controlId="formBasicName"
              >
                <Form.Control
                  type="password"
                  placeholder="Temporary password *"
                  name="oldPassword"
                  onChange={handleChange}
                  isInvalid={!!errors.oldPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.oldPassword}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control
                  type={"password"}
                  placeholder="Permanent password *"
                  name="newPassword"
                  onChange={handleChange}
                  isInvalid={!!errors.newPassword}
                />
                <span
                  className="icon"
                  //   onClick={() => setShowPassword((s) => !s)}
                >
                  {/* {showPassword ? <FiEyeOff /> : <FiEye />} */}
                </span>
                <Form.Control.Feedback type="invalid">
                  {errors?.newPassword}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control
                  type={"password"}
                  placeholder="Confirm permanent password *"
                  name="confirmPassword"
                  onChange={handleChange}
                  isInvalid={!!errors.confirmPassword}
                />
                <span
                  className="icon"
                  //   onClick={() => setShowPassword((s) => !s)}
                >
                  {/* {showPassword ? <FiEyeOff /> : <FiEye />} */}
                </span>
                <Form.Control.Feedback type="invalid">
                  {errors?.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>

              <Button variant="primary" type="submit">
                Update Password
              </Button>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default GlobalUpdatePasswordModal;

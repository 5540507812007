import React from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";

import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";

function BeforePrivacyPolicy() {
  const navigate = useNavigate();

  return (
    <>
      <Header />

      <main className="main static-page">
        <div className="container">
          <div className="headingSec">
            <h4>Privacy Policy</h4>
            <div className="back-link" onClick={() => navigate(-1)}>
              <BackIcon /> Back
            </div>
          </div>
          <div className="content">
            <p>
              This privacy policy forms part of our “Portal’s” (hereinafter
              collectively refers to partner website(s) and mobile
              application(s))Terms and Conditions, by accepting the Terms and
              Conditions and the Privacy Policy on the registration page, any
              person utilizing the Portal, hereinafter referred to as the "User
              or “you” or “your” or any of its features including participation
              in the various contests and online gaming, hereinafter referred to
              as "Contest(s)""Services" being conducted on the Portal shall be
              bound by this Privacy Policy. You consent to provide Personal
              Information and are aware of the purpose of sharing such
              information. If you do not wish to consent to sharing of the
              mandatory personal information with Golden Gaming International
              Private Limited, (hereinafter referred to as “Golden Gaming” or
              “we” or “us” or “our”) requested at the time of registration, you
              are requested to not to proceed with the registration.
              Registration procedure cannot be completed until information in
              the non-optional fields is provided.
            </p>
            <p>
              At Golden Gaming, we aim to provide you with a safe, protected and
              reliable environment to play. We understand and respect your
              privacy and thus assure you that any Personal Information provided
              by you to Golden Gaming is protected and will be dealt with in
              accordance to this Policy. Golden Gaming shall not provide,
              distribute, trade, sell or rent your name, e-mail address or other
              personal information provided on this site to any third party or
              information related to your usage of the Portal, except as
              provided in this Privacy Policy.
            </p>

            <h4>1. Information Gathered</h4>
            <p>
              The information collected from you (the user) will be in the
              course of providing you with access to the Services and to the
              features offered through the Portal.
            </p>
            <p>
              User's Personal Information includes the following types of
              Personal data or Information which has been categorised as under:
            </p>

            <h5 className="ps-3 ps-md-4">• Sensitive Personal Data:</h5>
            <p className="ps-3 ps-md-4">
              We do not normally collect Sensitive Personal Data except in
              accordance with or subject to this Privacy Policy. However, in
              certain circumstances, we may collect such information including
              but not limited to Financial information such as Bank account or
              credit card or debit card or other payment instrument details used
              by you to make any payments. We may also collect your Identity
              Proof, Age Proof and PAN Card details (as and when applicable).
            </p>
            <p className="ps-3 ps-md-4">
              You explicitly consent to provide such information as and when
              needed in accordance with this Privacy Policy and/or any
              agreement/contract entered into between Golden Gaming
              International Private Limited and the User. Except for any
              financial information that you choose to provide while making
              payment for any Services on the Portal, Golden Gaming
              International Private Limited does not collect any other Sensitive
              Personal Data in the course of providing the Services.
            </p>
            <p className="ps-3 ps-md-4">
              Financial information such as Bank account or credit card or debit
              card or other payment instrument details are not saved by Golden
              Gaming International Private Limited.
            </p>

            <h5 className="ps-3 ps-md-4">• Other Personal Information:</h5>
            <p className="ps-3 ps-md-4">
              We collect the follow Personal Information during the course of
              offering you Services on the Golden Gaming website:
            </p>
            <ul className="ps-3 ps-md-4">
              <li>Name</li>
              <li>Date of birth</li>
              <li>Contact number</li>
              <li>Postal/Contact address</li>
              <li>Account password</li>
              <li>The geo-location of your device</li>
              <li>IP address of your device, browser type and language.</li>
              <li>The date and the time during which you accessed the site.</li>
              <li>
                The address of the website which you may have used to link to
                the Website.
              </li>
              <li>
                Such other information that is defined as sensitive personal
                data or information in law.
              </li>
            </ul>

            <h4>
              2. Information from Social Media Platforms and Other
              Intermediaries:
            </h4>
            <p>
              Further, Golden Gaming collects certain user information from
              social media platforms such as Facebook and intermediaries such as
              Google in order to help the users create an account on the Golden
              Gaming Portal. The information collected by Golden Gaming is
              stored in our database of and further displayed in the Profile
              section of the User in order to provide an identification of the
              User on the Golden Gaming Portal.
            </p>
            <p>
              You shall be entitled to update the information provided by you by
              contacting us which shall be updated subject to reasonable checks
              and confirmation by Golden Gaming.
            </p>
            <p>
              We restrict access of personal information to our employees,
              contractors and agents and only allow access to those who need to
              know that information in order to process it on our behalf.
            </p>

            <h4>3. Usage of the Information</h4>
            <p>
              All users of Golden Gaming consent to the use of Sensitive
              Personal Data and Other Personal Information for the purpose
              stated in this policy. Golden Gaming may use the information
              gathered for the following purposes:
            </p>
            <p>
              For the Purpose of Registration and to provide you access to the
              Services and features offered through the Portal and to verify
              your identity, Users would be required to provide certain
              information for the registration process namely:
            </p>
            <ul className="ps-3 ps-md-4 mb-3">
              <li>Username</li>
              <li>Password</li>
              <li>Email address</li>
              <li>Date of birth</li>
              <li>PAN number (as applicable)</li>
            </ul>
            <p>
              In certain instances, we may collect certain Sensitive Personal
              Data from you on the Portal such as information regarding the
              payment instrument/modes used by you to make such payments, which
              may include cardholder name, credit/debit card number (in
              encrypted form) with expiration date, banking details, wallet
              details etc. This information is presented to you at the time of
              making a payment to enable you to complete your payment
              expeditiously. Except for any financial information that you
              choose to provide while making payment for any Services on the
              Portal, Golden Gaming does not collect any other Sensitive
              Personal Data in the course of providing the Services. Any
              Sensitive Personal Data collected by Golden Gaming shall not be
              disclosed to any third party without your express consent, save as
              otherwise set out in this Privacy Policy or as provided in a
              separate written agreement between Golden Gaming and you or as
              required by applicable law. It is clarified that this condition
              shall not apply to publicly available information, including
              Sensitive Personal Data, in relation to you on the Portal.
            </p>
          </div>
        </div>
      </main>

      <BeforeLoginFooter />
    </>
  );
}

export default BeforePrivacyPolicy;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { APP_CONST } from "../../config/const";
import { handleLeftSideBar } from "../../redux/app/actions";
import ModalWrapper from "../../containers/ModalWrapper";

import UserIcon from "../../assets/images/icons/user-icon.webp";
import CloseIcon from "../../assets/images/icons/close-icon.webp";
import SportsIcon from "../../assets/images/icons/sports-cion.webp";
import Exchange from "../../assets/images/icons/exchange.webp";
import CasinoIcon from "../../assets/images/icons/casino-icon.webp";
import LiveCasinoIcon from "../../assets/images/icons/live-casino-icon.webp";
import AviatorIcon from "../../assets/images/icons/aviator-icon.webp";
import AviatorLogo from "../../assets/images/icons/aviator-logo.webp";
import HomeIcon from "./../../assets/images/icons/leftbar/home-icon.webp";
import Evolution from "../../assets/images/icons/leftbar/evolution.webp";
import Ezugi from "../../assets/images/icons/leftbar/ezugi.webp";
import Supernowa from "../../assets/images/icons/leftbar/supernowa.webp";
import Vivo from "../../assets/images/icons/leftbar/vivo.webp";
import XPG from "../../assets/images/icons/leftbar/xpg.webp";
import Worldcasino from "../../assets/images/icons/leftbar/worldcasino.webp";
import Qtech from "../../assets/images/icons/leftbar/qtech.webp";

const LeftBarSports = () => {
  const { user } = useSelector((state) => state.auth);
  const { isAuth } = useSelector((state) => state.auth);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState("login");
  const { showLeftSideBar } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const location = useLocation();
  const [activePage] = useState("");

  const handleModalShow = (modalName) => {
    // setShowAuthModals((p) => ({ ...p, [modalName]: true }));
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };

  const providers = [
    {
      name: "Evolution",
      path: "/casino/evolution",
      isIcon: true,
      icon: Evolution,
    },
    { name: "Ezugi", path: "/casino/ezugi", isIcon: true, icon: Ezugi },
    {
      name: "Supernowa",
      path: "/casino/supernowa",
      isIcon: true,
      icon: Supernowa,
    },
    { name: "Vivo", path: "/casino/vivo", isIcon: true, icon: Vivo },
    { name: "XPG", path: "/casino/xpg", isIcon: true, icon: XPG },
    {
      name: "Worldcasino",
      path: "/casino/worldcasino",
      isIcon: true,
      icon: Worldcasino,
    },
    { name: "Qtech", path: "/casino/qtech", isIcon: true, icon: Qtech },
  ];

  return (
    <>
      <aside className="side-menu">
        <div className="leftbarSec">
          <div className="close-bar">
            <a
              href="#"
              className="close-btn"
              onClick={() => dispatch(handleLeftSideBar(!showLeftSideBar))}
            >
              <img src={CloseIcon} alt="close" />
            </a>
          </div>

          {!isAuth && (
            <div className="user bfr-lgn">
              <div className="user-login">
                {/* <div className="img-box">
                  <img src={UserIcon} alt="user" />
                </div> */}

                {/* <div className="signup">
                  <Button
                    variant=""
                    onClick={() => {
                      handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
                    }}
                    className="btn_signup"
                  >
                    Sign Up
                  </Button>
                </div> */}
              </div>
            </div>
          )}

          {isAuth && (
            <div className="user aftr-lgn">
              <div className="user-img">
                <img src={UserIcon} alt="user" />
              </div>

              <div className="user-name">
                <h5>{user?.mstruserid}</h5>
                <h6>{user?.mobileno}</h6>
              </div>
            </div>
          )}

          <div className="menu-link">
            <ul>
              <li>
                <a
                  href="/home"
                  className={activePage == "home" ? "active" : ""}
                >
                  <img src={HomeIcon} alt="home" />
                  <span>Home</span>
                </a>
              </li>
              <li>
                <a
                  href="/betby"
                  className={activePage == "betby" ? "active" : ""}
                >
                  <img src={SportsIcon} alt="Sports" />
                  <span>Sports</span>
                </a>
              </li>
              <li>
                <a
                  href="/sports/Inplay"
                  className={activePage == "sports" ? "active" : ""}
                >
                  <img src={Exchange} alt="exchange" />
                  <span>Exchange</span>
                </a>
              </li>
              <li>
                <a
                  href="/casinogames"
                  className={activePage == "casinogames" ? "active" : ""}
                >
                  <img src={CasinoIcon} alt="Casino" />
                  <span>Casino</span>
                </a>
              </li>
              <li>
                <a
                  href="/livecasino"
                  className={activePage == "livecasino" ? "active" : ""}
                >
                  <img src={LiveCasinoIcon} alt="live casino" />
                  <span>Live-games</span>
                </a>
              </li>
              <li className="seprator">
                <div></div>
              </li>
              <li>
                <a
                  href="casino/spribe/aviator"
                  className={`game-logo ${
                    activePage == "aviator" ? "active" : ""
                  }`}
                >
                  <img src={AviatorIcon} alt="aviator" />
                  <span>
                    <img src={AviatorLogo} alt="aviator" />
                  </span>
                </a>
              </li>
              <li className="seprator">
                <div></div>
              </li>

              {providers.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.path}
                    className={
                      location.pathname === item.path ||
                      activePage === item.activeKey
                        ? "active"
                        : ""
                    }
                  >
                    <img src={item.icon} alt={item.name} />
                    <span>{item.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </aside>

      {showAuthModals && (
        <ModalWrapper
          defaultShow={defaultModal}
          handleClose={setShowAuthModals}
        />
      )}
    </>
  );
};

export default LeftBarSports;

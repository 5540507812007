import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { handleLeftSideBar } from "../redux/app/actions";
import LeftBarSports from "../views/components/LeftBarSports";

import MainIcon from "../assets/images/icons/bottom-menu/main-icon.webp";
import SportsIcon from "../assets/images/icons/bottom-menu/sports-icon.webp";
import ESportsIcon from "../assets/images/icons/bottom-menu/esports-icon.webp";
import CasinoIcon from "../assets/images/icons/bottom-menu/casino-icon.webp";
import LiveCasinoIcon from "../assets/images/icons/bottom-menu/live-casino-icon.webp";
import MenuIcon from "../assets/images/icons/bottom-menu/menu-icon.webp";

export default function BottomNav() {
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const dispatch = useDispatch();
  const { showLeftSideBar } = useSelector((state) => state.app);
  const htmlElement = document.documentElement;

  useEffect(() => {
    if (showLeftSideBar) {
      htmlElement.style.overflow = "hidden";
      document.body.classList.add("leftbar-opened");
    } else {
      htmlElement.style.overflow = "auto";
      document.body.classList.remove("leftbar-opened");
    }
  }, [showLeftSideBar]);

  const footerPages = [
    "/home",
    "/sports/Inplay",
    "/betby",
    "/vip-level",
    "/casinogames",
    "/livecasino",
    "/",
  ];

  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find(
      (f) =>
        location?.pathname === f || (f === "/" && location.pathname === "/")
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <>
      <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 d-block d-md-none">
        <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
          <li>
            <a
              className={
                activePage === "/" || activePage === "/home" ? "active" : ""
              }
              href="/home"
            >
              <div className="imgBox">
                <img src={MainIcon} alt="SportsIcon" />
              </div>
              <span>Main</span>
            </a>
          </li>
          <li>
            <a
              className={activePage === "/betby" ? "active" : ""}
              href="/betby"
            >
              <div className="imgBox">
                <img src={SportsIcon} alt="SportsIcon" />
              </div>
              <span>Sports</span>
            </a>
          </li>
          <li>
            <a
              className={activePage === "/sports/Inplay" ? "active" : ""}
              href="/sports/Inplay"
            >
              <div className="imgBox">
                <img src={ESportsIcon} alt="SportsIcon" />
              </div>
              <span>Exchange</span>
            </a>
          </li>
          <li>
            <a
              href="/casinogames"
              className={activePage === "/casinogames" ? "active" : ""}
            >
              <div className="imgBox">
                <img src={CasinoIcon} alt="SportsIcon" />
              </div>
              <span>Casino</span>
            </a>
          </li>
          <li>
            <a
              className={activePage === "/livecasino" ? "active" : ""}
              href="/livecasino"
            >
              <div className="imgBox">
                <img src={LiveCasinoIcon} alt="SportsIcon" />
              </div>
              <span>Live-games</span>
            </a>
          </li>
          <li>
            <a onClick={() => dispatch(handleLeftSideBar(!showLeftSideBar))}>
              <div className="imgBox">
                <img src={MenuIcon} alt="SportsIcon" />
              </div>
              <span>Menu</span>
            </a>
          </li>
        </ul>
      </div>

      <LeftBarSports />
    </>
  );
}

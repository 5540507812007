import React from "react";
import { useSelector } from "react-redux";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Footer from "../../../../containers/Footer";

import VipLD1 from "../../../../assets/images/banner/lucky-draw/VipLD1.webp";
import VipLD2 from "../../../../assets/images/banner/lucky-draw/VipLD2.webp";
import VipLD3 from "../../../../assets/images/banner/lucky-draw/VipLD3.webp";
import VipLD4 from "../../../../assets/images/banner/lucky-draw/VipLD4.webp";
import VipLD5 from "../../../../assets/images/banner/lucky-draw/VipLD5.webp";

function LuckyDraw() {
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main">
        <div className="vip-gifts lucky-draw">
          <div className="inner-box">
            <div className="content-box">
              <div className="heading">Lucky Draw</div>

              <h5>Top 5 depositor of the month</h5>
              <ul>
                <li>
                  <div className="">
                    <img src={VipLD1} alt="bronze" />
                  </div>
                </li>
                <li>
                  <div className="">
                    <img src={VipLD2} alt="silverr" />
                  </div>
                </li>
                <li>
                  <div className="">
                    <img src={VipLD3} alt="gold" />
                  </div>
                </li>
                <li>
                  <div className="">
                    <img src={VipLD4} alt="platinum" />
                  </div>
                </li>
                <li>
                  <div className="">
                    <img src={VipLD5} alt="platinum" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>

      {!isAuth ? <BeforeLoginFooter /> : <Footer />}
    </>
  );
}

export default LuckyDraw;

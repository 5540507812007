import { useEffect } from "react";
import { UPDATE_PASSWORD_FLAG_KEY } from "../config/const";

const usePasswordFlagHook = (isAuth) => {
  const isUpdateFlag =
    localStorage.getItem(UPDATE_PASSWORD_FLAG_KEY) === "true";

  useEffect(() => {
    if (
      isUpdateFlag &&
      isAuth &&
      window.location.href.indexOf("update-password") < 0
    ) {
      window.location.href = "/update-password";
    }
  }, [isUpdateFlag]);
};

export default usePasswordFlagHook;
